<template>
   <CModal
    centered
    :show="show"
    @update:show="close"
    :title="$t('marketplace.tokens')"
    class="hintModal"
    >
    <div class="hintModal__content">
      <div>
        <CIcon
        name="coin_3"/>
        <span>
          {{ $t('marketplace.tokenPopoverDescCreator') }}
        </span>
      </div>
      <div>
        <CIcon
        name="coin_2"/>
        <span>
          {{ $t('marketplace.tokenPopoverDescInfluencer') }}
        </span>
      </div>
      <div>
        <CIcon
        name="coin_1"/>
        <span>
          {{ $t('marketplace.tokenPopoverDescFollower') }}
        </span>
      </div>
    </div>
    <div class="hintModal__btnBlock">
      <CButton
      class="shadow-none hintModal__btn"
      color="primary"
      @click="close">
        OK
      </CButton>
    </div>
    <template #footer-wrapper><span></span></template>
  </CModal>
</template>

<script>

export default {
  name: "HintModal",
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    close(){
      this.$emit('close')
    },
  }
}
</script>

<style lang="scss" scoped>
.hintModal{
  &__btn {
    width: 25%;
    height: 44px;
    margin-left: auto;
  }

  &__btnBlock {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
  }
  &__content{
    display: flex;
    flex-direction: column;
    gap: 10px;
    & div{
      display: flex;
      gap: 5px;
      & svg{
        flex-shrink: 0;
        margin-top: 3px;
      }
      & span{
        text-align: left;
      }
    }
  }
}
</style>
