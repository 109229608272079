var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CModal",
    {
      staticClass: "deleteGroupNotification",
      attrs: {
        title: _vm.$t("lk.settings.deleteWatchlistModal.title", {
          name: _vm.currentNotification.name,
        }),
        centered: "",
        scrollable: "",
        show: _vm.show,
      },
      on: { "update:show": _vm.close },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "w-100" },
                [
                  _c(
                    "CLoadingButtonCustom",
                    {
                      staticClass: "mr-2",
                      attrs: {
                        color: "primary",
                        loading: _vm.loading,
                        disabled: _vm.loading,
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.deleteNotification.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("general.yes")) + " ")]
                  ),
                  _c(
                    "CButton",
                    { attrs: { color: "dark" }, on: { click: _vm.close } },
                    [_vm._v(" " + _vm._s(_vm.$t("general.no")) + " ")]
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("p", [
        _vm._v(_vm._s(_vm.$t("marketplace.deleteGroupNotificationText"))),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }