var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CModal",
    {
      staticClass: "complainModal",
      attrs: {
        title: _vm.$t("lk.notifications.confirmAction"),
        centered: "",
        scrollable: "",
        show: _vm.show,
        closeOnBackdrop: false,
      },
      on: { "update:show": _vm.close },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "w-100" },
                [
                  _c(
                    "CButton",
                    {
                      staticClass: "mr-2",
                      attrs: { color: "primary" },
                      on: { click: _vm.confirm },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("general.confirm")) + " ")]
                  ),
                  _c(
                    "CButton",
                    { attrs: { color: "dark" }, on: { click: _vm.close } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("lk.videoEncrypt.UploadVideoModal.btnCancel")
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [_c("span", [_vm._v(" " + _vm._s(_vm.info) + " ")])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }