<template>
  <CModal
    :title="$t('lk.notifications.confirmAction')"
    centered
    scrollable
    :show="show"
    @update:show="close"
    class="complainModal"
    :closeOnBackdrop="false"
    >
      <span>
        {{ info }}
      </span>

      <template v-slot:footer>
        <div class="w-100">
          <CButton
            color="primary"
            class="mr-2"
            @click="confirm"
          >
            {{$t('general.confirm')}}
          </CButton>
          <CButton
            color="dark"
            @click="close"
          >
            {{$t('lk.videoEncrypt.UploadVideoModal.btnCancel')}}
          </CButton>
        </div>
      </template>
    </CModal>
</template>

<script>
export default {
  name: "ConfirmModal",
  props:{
    show: {
      type: Boolean,
      default: false
    },
    action:{
      type: String,
      default: ''
    },
    info: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
    }
  },
  methods:{
    close(){
      this.$emit('close')
    },
    confirm(){
      this.$emit('confirm')
    }
  }
}
</script>

<style lang="scss" scoped>
.complainModal{
  /deep/ .modal-footer{
    display: initial !important;
  }
  /deep/ .modal-header{
    border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
  }
}

</style>
