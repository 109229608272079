<template>
  <CModal
    v-if="show"
    :show="show"
    @update:show="close"
    centered
    scrollable
    class="notificationInfoModal"
    :onKey="escClose"
    :closeOnBackdrop="false"
  >
    <template #header>
      <div class="notificationInfoModal__header">
        <div class="notificationInfoModal__header-inner-1 d-flex flex-wrap align-items-end gap-3">
          <div>
            <span class="notificationInfoModal__label _header">
              {{ $t("marketplace.notificationName") }}
            </span>
            <div class="notificationInfoModal__value">
              <div class="modal-title">
                {{ currentNotification.name }}
              </div>
              <CIcon
                v-if="!isGuest"
                class="notificationInfoModal__header-complain"
                name="complain"
                @click.native="complain"
              />
              <div
                v-if="inLkList && currentNotification.is_public"
                class="notificationInfoModal__header-pin"
              >
                {{ $t("general.public") }}
              </div>
              <div
                v-if="inLkList && !currentNotification.can_subscribe"
                class="notificationInfoModal__header-pin --inactive"
              >
                {{ $t("lk.notifications.isInActive") }}
              </div>
            </div>
          </div>

          <ExchangeLogo :is-futures="isFutures" />
        </div>

        <CIcon
          class="notificationInfoModal__header-close"
          name="cil-x"
          @click.native="close"
        />
      </div>
    </template>
    <ComplainModal
      :show="showComplainModal"
      :currentNotification="currentNotification"
      @close="showComplainModal = false"
    />
    <ConfirmModal
      :show="showConfirmModal"
      :info="confirmModalInfo"
      @close="closeConfirmModal"
      @confirm="confirmHandler"
    />
    <hook-modal
      :currentHook="currentHook"
      :show="showCreateHookModal"
      :is-futures="isFutures"
      @saveHook="saveHook"
      @close="closeHookModal"
    />
    <template v-if="!telegramVerify || !isSubscribed">
      <button
        class="mb-3 category"
        @click="collapseNotifySettings = !collapseNotifySettings"
      >
        <span class="category-title">
          1
        </span>
        <span class="category-text">
          {{ $t('lk.notifications.notificationSettings') }}
        </span>
        <span class="dropdown-divider w-100" />
        <CIcon
          name="cil-chevron-circle-down-alt"
          class="cil-chevron"
          :class="{ _show: !collapseNotifySettings }"
        />
      </button>

      <c-collapse :show="!collapseNotifySettings" :duration="200">
        <web-push-toggle
          v-if="($store.getters['user/getPermissions']('view_notifications') && !isSubscribed)"
        />
        <confirm-tg
        color="primary"
        :text="$t('lk.account.connectTelegram')"
        class="px-2 mb-2 mt-3"
        :isRequestPassed="telegramVerify"
        v-if="!telegramVerify" />
      </c-collapse>

      <!-- <div class="my-3 category">
        <div class="category-title">
          2
        </div>
        <div class="category-text">
          {{ $t('lk.notifications.creatingNotification') }}
        </div>
        <div class="dropdown-divider w-100" />
      </div> -->
    </template>
    <template v-if="telegramVerify || isSubscribed">
      <div class="notificationInfoModal__section">
        <span class="notificationInfoModal__label">
          {{ $t('marketplace.coinsNumber') }}
        </span>
        <div class="notificationInfoModal__coinsAmount">
          <span class="notificationInfoModal__value">
            {{ currentNotification.base_coins_count }}
          </span>
          <span
            v-if="canUserSubscribe"
            @click="showCoinList = !showCoinList"
            class="notificationInfoModal__coinsShowToggler">
              <CIcon
                v-if="showCoinList"
                class="notificationInfoModal__coinsShowToggler-showIcon"
                name="unshow"
              />
              <CIcon
                v-else
                class="notificationInfoModal__coinsShowToggler-showIcon"
                name="show"
              />
              {{ $t('marketplace.show') }}
              <CIcon
                :class="['notificationInfoModal__coinsShowToggler-arrowIcon',{_active: showCoinList}]"
                name="arrow-trends"
              />
          </span>
        </div>
        <transition name="fade" appear>
          <div
            v-if="showCoinList"
            class="notificationInfoModal__coinsList">
            <span
              v-for="item in currentNotification.coins_pairs"
              :key="item.id"
              @click="coinPinClick(item)"
              :class="{'notificationInfoModal__coinsList-item': true,_inactive: !isCoinPinActive(item)}">
              {{ `${item.exchange}:${item.symbol}` }}
            </span>
          </div>
        </transition>

      </div>
      <div v-if="!isFutures" class="notificationInfoModal__section">
        <CInputCheckbox
          v-if="currentNotification.sync_watchlist"
          disabled
          :checked="currentNotification.sync_watchlist"
          custom
          :label="$t('lk.notifications.syncedFutures')"
        />
      </div>
      <div class="notificationInfoModal__section">
        <div class="d-flex">
          <div>
            <span class="notificationInfoModal__label">
            {{ $t('marketplace.createdAt') }}
            </span>
            <div class="notificationInfoModal__coinsAmount">
              {{ publishedDate }}
            </div>
          </div>
          <div
          v-if="currentNotification.subscribe_finished_at"
          class="ml-3">
            <span class="notificationInfoModal__label">
            {{ $t('marketplace.subscribeEndDate') }}
            </span>
            <div class="notificationInfoModal__coinsAmount">
              {{ currentNotification.subscribe_finished_at }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="canUserSubscribe" class="notificationInfoModal__section">
        <span class="notificationInfoModal__label">
          {{ $t('marketplace.activity') }}:
        </span>
        <CButtonGroup class="d-flex">
          <CButton
            v-for="(item, i) in subscribeDurationList"
            :key="i"
            color="dark"
            class="cbutton"
            :class="{ disabled: getDurationTabDisabled(item.value) }"
            :variant="currentNotification.subscribe_duration.id === item.id ? '' : 'outline'"
            @click="setSubscribeDuration(item)"
            v-tooltip="getSubscribeDurationTooltip(item.value)"
          >
          {{ `${item.value} ${$tc(item?.labelI18n, item.value)}` }}
          </CButton>
        </CButtonGroup>
      </div>
      <div v-if="canUserSubscribe" class="mb-4">
        <span class="notificationInfoModal__label">
          {{ $t('lk.notifications.target.title') }}:
        </span>
        <CButtonGroup class="d-flex">
          <CButton
            v-for="(type, i) in NotificationClass.sendTypes"
            v-tooltip="{
              content: getTooltipContent(type),
            }"
            :key="i"
            :disabled="loading"
            color="dark"
            class="cbutton"
            :variant="getSendType(type) ? '' : 'outline'"
            :class="{ disabled: getSendTypeDisabled(type) }"
            @click="setSendType(type)"
          >
            {{ getSendTypeLabel(type) }}
          </CButton>
        </CButtonGroup>
        <transition name="fade" appear>
          <CButton
            v-if="isSendTypesChanged && currentNotification.is_public && inLkList"
            :disabled="loading"
            class="cbtn mt-2"
            color="success"
            @click="saveSignal"
          >
            {{ $t('general.apply') }}
          </CButton>
        </transition>
      </div>
      <transition name="fade" appear>
        <div
          v-if="!(!currentNotification.is_single && !currentNotification.is_public && !currentNotification.hooks.length) && sendTypes.includes('hook')"
          class="notificationInfoModal__section"
        >
          <span class="notificationInfoModal__label">
            Webhook:
          </span>
          <div class="notificationInfoModal__hooks-wrapper">
            <div
              v-for="(item, i) in currentNotification.hooks"
              :key="i"
              class="notificationInfoModal__hook-item"
            >
              <div class="notificationInfoModal__hook-info mr-3">
                {{item.name}}
              </div>
              <div class="notificationInfoModal__hook-controls">
                <CButton
                  v-if="!currentNotification.is_public"
                  color="primary"
                  @click="showHook(item, i)"
                  >
                  <CIcon name="cil-info" />
                </CButton>
                <template v-else>
                  <CSwitch
                    color="primary"
                    shape="pill"
                    :checked.sync="item.enable"
                    :disabled="currentNotification.byUser || currentNotification.is_subscribed"
                    class="switch-custom mr-3"
                    id="switch"
                  />
                  <CButton
                    class="mr-2"
                    color="success"
                    @click="editHook(item, i)"
                  >
                    <CIcon name="cil-pencil" />
                  </CButton>
                  <CButton
                    color="danger"
                    @click="deleteHook(item, i)"
                  >
                    <CIcon name="cil-trash" />
                  </CButton>
                </template>
              </div>
            </div>
          </div>
          <div v-if="!currentNotification.hooks.length">
          <CButton
            :disabled="loading || (this.currentNotification.is_subscribed && this.currentNotification.is_public)"
            class="shadow-none text-white px-0"
            @click="addHook"
          >
            <CIcon
              name="cil-plus"
              class="cc-icon bg-dark align-bottom rounded-circle my-0 mr-1 p-1"
            />
            {{ $t('general.add') }}
          </CButton>
        </div>
        </div>
      </transition>
      <div class="notificationInfoModal__section">
        <span class="notificationInfoModal__label">
          {{ $t('marketplace.totalSigned') }}
        </span>
        <div>
          <span class="notificationInfoModal__value">
            {{currentNotification.subscribers_count}}
            <CIcon
            name="person"
            />
          </span>
          <div class="notificationInfoModal__author">
            <div class="notificationInfoModal__person-pin">
              <CIcon
              name="person1"
              />
            </div>
            <span class="label">
              {{ $t('marketplace.author') }}:
            </span>
            <span class="value">
              {{currentNotification?.creator?.name}}
            </span>
          </div>
        </div>
      </div>
      <div v-if="canUserSubscribe" class="notificationInfoModal__section">
        <span class="notificationInfoModal__label">
          {{ $t('marketplace.terms') }}
        </span>
        <div v-if="initLoading">
          <CSpinner
            :class="'mt-1'"
            :size="'sm'"
            :color="'primary'"
          />
        </div>
        <div v-else class="notificationInfoModal__value d-flex flex-column align-items-start --terms">
          <p
          v-for="(v, i) in currentNotification.conditions"
          :key="i"
          class="m-0"
          >
            <span v-if="v.tf !== 'price'" class="mr-1">{{v.tf}}</span>
            <span class="mr-1">{{currentNotification.conditionName(v.param)}}</span>
            <span v-if="v.hasValue">{{v.isNegative ? v.sign : ''}}{{ getValue(v.param, v.value) }}</span>
          </p>
        </div>
      </div>
      <div v-if="canUserSubscribe && currentNotification.coins_count > 1" class="notificationInfoModal__section">
        <div class="d-flex mb-2 align-items-center">
          <CInputCheckbox
          class="mr-3"
          :checked.sync="currentNotification.tv_notify_enable"
          custom
          :label="$t('lk.notifications.plotOnTheChart')"
          :disabled="currentNotification.is_subscribed"
          />
          <transition name="fade" appear>
            <tv-icons-picker
              v-if="currentNotification.tv_notify_enable"
              :open.sync="openTvIconPicker"
              :color.sync="currentNotification.tv_notify_color"
              :icon.sync="currentNotification.tv_notify_icon"
              :size.sync="currentNotification.tv_notify_size"
            >
              <button
                v-tooltip="{
                  content: currentNotification.is_subscribed ? null : $t('lk.tg_notifications.edit'),
                }"
                class="color-btn"
                @click="tvIconPickerClickHandler"
              >
                <span class="tv-icon-tag"
                      :style="{
                        color : currentNotification.tv_notify_color ? currentNotification.tv_notify_color: '#ff0000',
                        'font-size' : currentNotification.tv_notify_size ? currentNotification.tv_notify_size + 'px' : '19px',
                        width: currentNotification.tv_notify_size ? (currentNotification.tv_notify_size + 5) + 'px' : '24px',
                        height: currentNotification.tv_notify_size ? (currentNotification.tv_notify_size + 5) + 'px' : '24px',
                        'line-height': currentNotification.tv_notify_size ? (currentNotification.tv_notify_size + 5) + 'px' : '24px'
                      }"
                      v-html="'&#' + (currentNotification.tv_notify_icon ? currentNotification.tv_notify_icon : 0xf0a2) + ';'"
                />
              </button>
            </tv-icons-picker>
          </transition>
        </div>
        <transition name="fade" appear>
          <div
          v-if="currentNotification.tv_notify_enable"
          class="mb-4">
            <span class="notificationInfoModal__label">
              {{ $t('lk.notifications.plotOnChartDuration') }}:
            </span>
            <CButtonGroup class="d-flex">
              <CButton
                v-for="(item, i) in NotificationClass.plotOnChartDurationList"
                :key="i"
                color="dark"
                class="cbutton"
                :disabled="currentNotification.is_subscribed"
                :variant="currentNotification.tv_notify_days.id === item?.id ? '' : 'outline'"
                @click="$set(currentNotification, 'tv_notify_days', item)"
              >
                {{ item?.labelI18n ? `${item.value} ${$tc(item?.labelI18n, item.value)}` : $t(item.label) }}
              </CButton>
            </CButtonGroup>
          </div>
        </transition>
      </div>
      <div
      v-if="currentNotification.description"
      class="notificationInfoModal__section">
        <span class="notificationInfoModal__label">
          {{ $t('lk.videoEncrypt.EditModal.description') }}
        </span>
        <span
        class="notificationInfoModal__value --terms formatedText">
          {{ currentNotification.description }}
        </span>
      </div>
      <div
      v-if="currentNotification.comment && canUserSubscribe && (currentNotification.is_subscribed || currentNotification.byUser)"
      class="notificationInfoModal__section">
        <span class="notificationInfoModal__label">
          {{ $t('lk.notifications.comment') }}
        </span>
        <span class="notificationInfoModal__value --terms">
          {{ currentNotification.comment }}
        </span>
      </div>
      <div
      v-if="!currentNotification.is_subscribed && canUserSubscribe && !currentNotification.byUser"
      class="notificationInfoModal__section">
        <span class="notificationInfoModal__label">
          {{ $t('lk.notifications.comment') }}
        </span>
        <CTextarea
          :rows="commentLengthLimit === 50 ? 4 : 5"
          class="mb-0 w-100"
          ref="commentInput"
          :value="comment"
          @input="commentInputHandler"
        />
        <span class="notificationInfoModal__comment-input-counter">
          {{ commentLengthLimit }}/{{ comment.length }}
        </span>
      </div>
    </template>

    <template v-slot:footer>
      <div class="notificationInfoModal__controls">
        <template>
          <CButton
            v-if="!isSubscribed && !telegramVerify"
            :disabled="loading"
            class="cbtn flex-grow-1"
            color="dark"
            @click="close"
          >
            {{ $t('general.close') }}
          </CButton>
          <template v-else>
            <CLoadingButtonCustom
              v-if="!isLog"
              :loading="loading"
              :disabled="loading"
              class="notificationInfoModal__action-button"
              :color="buttonOptions.color"
              @click.native="buttonOptions.handler"
              v-tooltip="subscribeButtonToolTip"
            >
              <CIcon
                v-if="buttonOptions.hasIcon"
                name="cil-plus" />
              {{ buttonOptions.label }}
            </CLoadingButtonCustom>
            <CButton
              v-if="currentNotification.is_subscribed && !currentNotification.is_subscribed_active && !currentNotification.byUser && currentNotification.can_subscribe && currentUser.getAccess(2)"
              :loading="loading"
              :disabled="loading"
              color="warning"
              @click="reSubscribe"
              v-tooltip="{content: $t('marketplace.reSubscribeTooltip')}"
            >
              <CIcon name="cil-reload"/>
            </CButton>
            <CButton
              v-if="inLkList && !currentNotification.can_subscribe"
              :loading="loading"
              :disabled="loading"
              color="warning"
              @click="recreateNotification"
              v-tooltip="{content: $t('marketplace.reCreateTooltip')}"
            >
              <CIcon name="cil-reload"/>
            </CButton>
            <div
              v-if="!(currentNotification.is_subscribed || currentNotification.byUser) && !isLog"
              class="notificationInfoModal__token">
            <span class="notificationInfoModal__token-count">
              1
            </span>
              <CIcon
                v-tooltip="{content: $t('marketplace.assignTokenTooltip')}"
                name="coin_1"/>
            </div>
            <CButton
              @click="copyLink"
              v-if="currentNotification.can_subscribe && currentNotification.is_public"
              color='secondary'
              class="notificationInfoModal__action-button">
              <!-- <span class="notificationInfoModal__ref-text"> -->
              {{ $t('general.share') }}
              <!-- </span> -->
              <CIcon
                name="copy" />
            </CButton>
            <CButton
              v-if="!currentNotification.is_public && !currentNotification.is_single"
              color="success"
              @click="editSignal"
            >
              <CIcon name="cil-pencil" />
            </CButton>
          </template>
        </template>
      </div>
    </template>

  </CModal>
</template>

<script>
import ConfirmModal from '@/components/marketplace/ConfirmModal'
import HookModal from '@/components/notifications/HookModal.vue';
import TvIconsPicker from '@/components/common/TvIconsPicker.vue';
import ConfirmTg from "@/components/account/ConfirmTg";
import WebPushToggle from "@/components/notifications/WebPushToggle";
import { copyTextToClipboard } from '@/assets/js/plugins/copyText';
import { mapGetters, mapState, mapActions } from "vuex";
import validate from "@/assets/js/notifications/validateSubscribeNotification";
import Notification from "@/assets/js/notifications/Notification.class";
import ComplainModal from '@/components/marketplace/ComplainModal'
import axios from "axios";
import cloneDeep from "lodash/cloneDeep";
import Hook from "@/assets/js/notifications/Hook.class";
import ExchangeLogo from '../ExchangeLogo.vue';

export default {
  name: "NotificationInfoModal",
  components: {
    ExchangeLogo,
    ComplainModal,
    WebPushToggle,
    ConfirmTg,
    TvIconsPicker,
    HookModal,
    ConfirmModal
  },
  props:{
    show: {
      type: Boolean,
      default: false
    },
    currentNotification:{
      type: Notification,
      default: () => ({})
    },
    inLkList: {
      type: Boolean,
      default: false,
    },
    isLog: {
      type: Boolean,
      default: false,
    },
    backTitle: {
      type: String,
      default: null
    }
  },
  data(){
    return{
      initLoading: false,
      confirmAction: null,
      confirmModalInfo: '',
      currentHookIndex: null,
      showConfirmModal: false,
      showCreateHookModal: false,
      currentHook: null,
      openTvIconPicker: false,
      collapseNotifySettings: false,
      comment: this.currentNotification.comment,
      tokens: [],
      sendTypes: [...this.currentNotification.sendTypes],
      initSendTypes: [...this.currentNotification.sendTypes],
      NotificationClass: Notification,
      showComplainModal: false,
      loading: false,
      showCoinList: false,
      selectedCoins: [...this.currentNotification.base_coins_pairs],
      selectedTab: 0,
      unitsList:[
        {
          label: 'K',
          step: 100,
          minVal: '100',
          //0s count after value
          value: 1000,
        },
        {
          label: 'M',
          step: 1,
          minVal: '1',
          //0s count after value
          value: 1000000,
        },
        {
          label: 'B',
          step: 0.5,
          minVal: '0.5',
          //0s count after value
          value: 1000000000,
        },
      ],
    }
  },
  created () {
    this.getConfig()
    if(this.backTitle){
      document.title = this.currentNotification.name
    }
  },
  destroyed () {
    if(this.backTitle){
      document.title = this.backTitle
    }
  },
  mounted(){
    this.getTokens()
    if(!this.currentNotification.is_subscribed){
      const lsSubscribeSendTypes = JSON.parse(localStorage.getItem('subscribeSendTypes'))
      const lsSubscribeDurationTypes = JSON.parse(localStorage.getItem('subscribeDurationTypes'))
      if(lsSubscribeSendTypes && Array.isArray(lsSubscribeSendTypes)){
        lsSubscribeSendTypes.forEach(item => {
          this.setSendType(item)
        })
      }

      if(lsSubscribeDurationTypes){
        this.$set(this.currentNotification, 'subscribe_duration', lsSubscribeDurationTypes)
      }
    }
    // const routeRef = this.$route?.query?.ref
    // const lsRef = localStorage.getItem('savedRef')
    // if(routeRef){
    //   if(routeRef === this.currentUser.referralCode) return
    //   if(!lsRef) localStorage.setItem('savedRef', routeRef)
    // }
  },
  watch:{
    collapseNotifySettings(val) {
      this.$store.dispatch("setKey", {
        namespace: 'notifications',
        key: 'collapseNotifySettings',
        value: val
      })
    },
  },
  computed:{
    isSendTypesChanged(){
      if (this.sendTypes.length !== this.initSendTypes.length) {
        return true;
      }
      const sortedArr1 = this.sendTypes.slice().sort();
      const sortedArr2 = this.initSendTypes.slice().sort();
      for (let i = 0; i < sortedArr1.length; i++) {
          if (sortedArr1[i] !== sortedArr2[i]) {
              return true;
          }
      }
      return false;
    },
    subscribeDurationList(){
      return Object.values(this.NotificationClass.durationList).map(item => {
        if(this.currentUser.tariffDaysLeft >= item.minVal && this.currentUser.tariffDaysLeft <= item.value){
          return {
            ...item,
            value: this.currentUser.tariffDaysLeft
          }
        }
        else{
          return item
        }
      })
    },
    commentLengthLimit(){
      return 50
    },
    refLink(){
      return `${document.location.origin}/marketplace?id=${this.currentNotification.id}&ref=${this.currentUser.referralCode}`
    },
    ...mapState({
      isSubscribed: state => state.browserNotify.isSubscribed,
      google2faEnabled: state => state.user.userData.google2faEnabled,
    }),
    ...mapGetters({
      telegramVerify: 'user/telegramVerify',
      isGuest: 'user/isGuest'
    }),
    publishedDate() {
      return this.currentNotification.published_date
    },
    currentUser(){
      return this.$store.state.user.userData
    },
    // byUser(){
    //   return this.currentUser.id === this.currentNotification.creator?.id
    // },
    subscribeButtonToolTip() {
      if (!this.canUserSubscribe) {
        return {content: `${this.$t('marketplace.actveTariffTooltip', {tariff: 'PRO+ALERTS'})}`}
      }

      return {content: false}
    },
    buttonOptions() {
      // if(this.inLkList){
        if(this.currentNotification.byUser) {
          return {
            label: this.$t('general.delete'),
            color: 'danger',
            handler: this.delete
          }
        }
        // }else{
        //   return {
        //     label: this.$t('marketplace.unsubscribe'),
        //     color: 'secondary',
        //     handler: this.toggleSubscribe
        //   }
        // }
      // }else{
        if(this.currentNotification.is_subscribed) {
          return {
            label: this.$t('marketplace.unsubscribe'),
            color: 'secondary',
            handler: this.toggleSubscribe
          }
        }
        if(!this.currentNotification.is_subscribed){
          return {
            label: this.$t('marketplace.subscribe'),
            color: 'primary',
            hasIcon: true,
            handler: this.toggleSubscribe,
            // tooltip: this.subscribeButtonToolTip,
          }
        }
        return {}
      // }
    },
    canUserSubscribe() {
      return this.currentUser.getAccess(2)
    },
    isFutures() {
      return this.currentNotification.is_futures
    },
  },
  methods:{
    getConfig() {
      this.initLoading = true
      axios.get('api/v1/signals/config', {
        params: {
          is_futures: this.isFutures ? 1 : null,
          locale: this.$root.$i18n.locale
        }
      }).then(({status, data}) => {
        if (status < 300) {
          Notification.sets = data?.data?.sets || []
          Notification.vars = data?.data?.vars || []
          Notification.vars_flat = data?.data?.vars_flat || []
          Notification.sendTypes = data?.data?.send_types || []
          Notification.vars_interval = data?.data?.vars_interval || []
          Notification.negative = data?.data?.negative || []
        }
      }).finally(() => {
        this.initLoading = false
      })
    },
    getSubscribeDurationTooltip(value){
      return {
        content: (this.currentNotification.is_subscribed || this.currentNotification.byUser) ? false
          : this.getDurationTabDisabled(value) ?
          this.$t('marketplace.subscribeDurationTooltip') : false
      }
    },
    async saveSignal(){
      this.loading = true
      try{
        this.currentNotification.sendTypes = this.sendTypes
        const res = await this.currentNotification.update(this.currentNotification.id)
        if (res.status < 300) {
            this.$toastr.success(this.$t('lk.notifications.signalChanged'))
            this.initSendTypes = [...this.sendTypes]
          }
      }finally{
        this.loading = false
      }
    },
    editSignal(){
      this.currentNotification.editMode = true
      this.$emit('editSignal', this.currentNotification)
    },
    async confirmHandler(){
      if(this.confirmAction === 'deleteHook'){
        await this.confirmDeleteHook()
      }
      this.closeConfirmModal()
    },
    closeConfirmModal(){
      this.currentHookIndex = null
      this.currentHook = null
      this.showConfirmModal = false
      this.confirmAction = null
    },
    addHook(){
      if(this.currentNotification.hooks.length === 1 || (this.currentNotification.is_subscribed && this.currentNotification.is_public)){
        return
      }
      this.currentHook = new Hook({editMode: true, enable: true})
      this.showCreateHookModal = true
    },
    confirmDeleteHook(){
      this.loading = true
      axios.delete(`api/v1/signals/with_hooks/${this.currentNotification.id}/${this.currentHook.id}`)
      .then(({status}) => {
        if (status < 300) {
          this.$toastr.success(this.$t('lk.notifications.signalChanged'))
          this.deleteHookHandler()
        }
      }).finally(()=>{
        this.loading = false
      })
    },
    deleteHook(item, index){
      this.currentHookIndex = index
      if(this.currentNotification.byUser || this.currentNotification.is_subscribed){
        this.currentHook = item
        this.confirmModalInfo = `${this.$t('lk.notifications.confirmDeleteHook')} ${item.name}`
        this.confirmAction = 'deleteHook'
        this.showConfirmModal = true
      }else{
        this.deleteHookHandler()
        this.currentHookIndex = null
      }
    },
    deleteHookHandler(){
      this.currentNotification.hooks.splice(this.currentHookIndex, 1)
    },
    editHook(item,i){
      const hook = item
      hook.editMode = true
      this.currentHook = item
      this.currentHook.editIndex = i
      this.showCreateHookModal = true
    },
    showHook(item, i){
      this.currentHook = item
      this.currentHook.editIndex = i
      this.showCreateHookModal = true
    },
    closeHookModal(){
      this.currentHook = null
      this.showCreateHookModal = false
    },
    async saveHook(item){
      const signalId = this.currentNotification.is_single ? this.currentNotification.marketplace_signal_id : this.currentNotification.id

      try{
        let res
        const editedHook = new Hook(item)
        if(!this.currentNotification.hooks.length){
          this.currentNotification.hooks.push(editedHook)
        }else{
          const editIndex = this.currentHook.editIndex
          if(this.currentHook.hasOwnProperty('editIndex')){
            delete this.currentHook.editIndex
            this.currentNotification.hooks[editIndex] = editedHook;
          }else{
            this.currentNotification.hooks.push(editedHook)
          }
        }
        if(this.currentNotification.byUser || this.currentNotification.is_subscribed){
          const reqUrl = this.currentHook.id ?
            `/api/v1/signals/with_hooks/${signalId}/${this.currentHook.id}` :
            `/api/v1/signals/with_hooks/${signalId}`

          res =  await axios.post(reqUrl,{
            ...new Hook(item || this.currentHook.hookObject)
          })
          if (res.status < 300) {
            this.currentNotification.hooks = res.data.data.hooks.map(item => new Hook(item))
            this.$toastr.success(this.$t('lk.notifications.signalChanged'))
          }
        }
      }catch(e){
        this.deleteHookHandler()
      }finally{
        this.closeHookModal()
      }
    },
    tvIconPickerClickHandler(){
      if(this.currentNotification.is_subscribed) return
      this.openTvIconPicker = true
    },
    getDurationTabDisabled(days){
      if(this.currentNotification.is_subscribed){
        return true
      }
      return days > 30 && this.currentUser.tariffDaysLeft < days

    },
    neededToken(tokenId = 1){
      return this.tokens.find(item => item?.token_id === tokenId)
    },
    getValue(param, value){
      console.log(Notification.vars_interval)
      const hasUnit = Notification.vars_interval?.[param]?.has_pretty_value
      if(hasUnit){
        const numberParams = this.getNumberParams(value)
        if(numberParams.magnitude > 0){
          // this.selectedUnits[index] = this.unitsList[numberParams.magnitude - 1]
          // this.notification.conditions[index].value = numberParams.scaled
          return `${numberParams.scaled} ${this.unitsList[numberParams.magnitude - 1].label}`
        }
        return value
      }
      return value
    },
    getNumberParams(num){
      const magnitude = Math.floor(Math.log10(num) / 3);
      const validMagnitude = magnitude > 3 ? 3 : magnitude
      const scaled = num / Math.pow(10, validMagnitude * 3);
      return { magnitude: validMagnitude, scaled}
    },
    commentInputHandler(val){
      if(val.length > this.commentLengthLimit) {
        this.$refs.commentInput.state = this.comment
      }
      if(val.length <= this.commentLengthLimit) this.comment = val
    },
    escClose(e,key){
      return (key === 27 && !this.showComplainModal)
    },
    recreateNotification(){
      const formatedData = cloneDeep(this.currentNotification)
      delete formatedData.id
      this.$emit('recreateNotification', formatedData)
    },
    async reSubscribe(){
      this.loading = true
      try{
        const res = await axios.post(`api/v1/signals/marketplace/${this.currentNotification.id}/resubscribe`)
        if(res.status < 300){
          if(res.data.error){
            this.$toastr.warning(res.data.error)
          }else{
            this.$toastr.success(this.$t('lk.notifications.subscribeSuccess'))
            this.close()
            this.$emit('update')
          }
        }
        // eslint-disable-next-line no-empty
      } catch(e){}
      finally {
        this.loading = false
      }
    },
    copyLink() {
      copyTextToClipboard(this.refLink, (status) => {
        if (status) {
          this.$toastr.success(this.$t('lk.referrals.copy_referral_link'));
        } else {
          this.$toastr.error(this.$t('lk.referrals.copy_referral_link_fail'));
        }
      });
    },
    ...mapActions('promo', ['toggleShowPromoModal']),
    getTokens(){
      const url = 'api/v2/marketplace-tokens/user'
      axios.get(url)
        .then(({status, data}) => {
          if (status < 300) {
            this.tokens = data.data
          }
        })
    },
    delete(){
      this.$emit('delete', this.currentNotification)
      this.close()
    },
    setSubscribeDuration(item){
      if (this.getDurationTabDisabled(item.value)) return
      localStorage.setItem('subscribeDurationTypes', JSON.stringify(item))
      this.$set(this.currentNotification, 'subscribe_duration', item)
    },
    getSendTypeLabel(type) {
      const obj = Notification.sendTypesList[type]

      if (obj?.i18nLabel) {
        return this.$t(obj.i18nLabel)
      } else return obj?.label || type
    },
    setSendType(type) {
      if (this.getSendTypeDisabled(type)) return

      const findIndex = this.sendTypes.findIndex(el => el === type)

      if (findIndex > -1) {
        if (this.sendTypes.length > 1) {
          this.sendTypes.splice(findIndex, 1)
        } else {
          this.sendTypes.splice(findIndex, 1)

          this.$nextTick(() => {
            this.sendTypes.push(type)
          })
        }
      } else {
        this.sendTypes.push(type)
      }
    },
    getSendTypeDisabled(type) {
      if(this.currentNotification.is_subscribed && this.currentNotification.is_public) return true

      if (Notification.isTg(type)) {
        return !this.telegramVerify
      }
      if (Notification.isWeb(type)) {
        return !this.isSubscribed
      }
      if (Notification.isHook(type)) {
        return !this.google2faEnabled
      }
      return false
    },
    getSendType(type) {
      const findIndex = this.sendTypes.findIndex(el => el === type)
      return findIndex > -1
    },
    getTooltipContent(type) {
      if (Notification.isTg(type)) {
        return !this.telegramVerify ? this.$t('lk.notifications.errorMessages.tg') : ''
      }
      if (Notification.isWeb(type)) {
        return !this.isSubscribed ? this.$t('lk.notifications.errorMessages.web') : ''
      }
      if (Notification.isHook(type)) {
        return !this.google2faEnabled ? this.$t('lk.notifications.errorMessages.hook') : ''
      }
      return ''
    },
    complain(){
      this.showComplainModal = true
    },
    async toggleSubscribe(){
      if (!this.canUserSubscribe){
        this.toggleShowPromoModal({show: true, content: { id: 'pro_alerts', sliderData: [{src:'promo1'}, {src:'promo2'}, {src:'promo3'}]}})
        this.close()
        return
      }
      if(!this.currentNotification.is_subscribed && !validate({pairs: this.selectedCoins, sendTypes: this.sendTypes})) return
      this.loading = true
      localStorage.setItem('subscribeSendTypes', JSON.stringify(this.sendTypes))
      try{
        let res
        if(!this.currentNotification.is_subscribed){
          if (!this.neededToken(1).active_token_count && !this.neededToken(2).active_token_count && !this.neededToken(3).active_token_count){
            this.$toastr.warning(this.$t('marketplace.tokenLimitWarning', {type: 'FOLLOWER/CREATOR/INFLUENCER'}))
            return
          }
          res = await axios.post(`api/v1/signals/marketplace/${this.currentNotification.id}/subscribe`,{
            subscribe_duration: this.currentNotification.subscribe_duration.value,
            send_types: this.sendTypes,
            pairs: this.selectedCoins,
            comment: this.comment,
            hooks: this.currentNotification.hooks,
            tv_notify_enable: this.currentNotification.tv_notify_enable,
            ...(this.currentNotification.tv_notify_enable && {
              tv_notify_color: this.currentNotification.tv_notify_color,
              tv_notify_icon: this.currentNotification.tv_notify_icon,
              tv_notify_size: this.currentNotification.tv_notify_size,
              tv_notify_days: this.currentNotification.tv_notify_days.value
            })
          })
        }else{
          res = await axios.post(`api/v1/signals/marketplace/${this.currentNotification.id}/unsubscribe`)
        }
        if(res.status < 300){
          if(res.data.error){
            this.$toastr.warning(res.data.error)
          }else{
            this.$toastr.success(this.$t(this.currentNotification.is_subscribed ? 'lk.notifications.unsubscribeSuccess' : 'lk.notifications.subscribeSuccess'))
            this.$emit('update')
            this.$emit('close')
          }
        }
        // eslint-disable-next-line no-empty
      } catch(e){}
      finally {
        this.loading = false
      }
    },
    close(){
      this.$emit('close')
    },
    isCoinPinActive(coin){
      return this.selectedCoins.some(item => item.id === coin.id)
    },
    coinPinClick(coin){
      if(this.currentNotification.is_subscribed){
        return
      }
      if(this.isCoinPinActive(coin)){
        this.selectedCoins = this.selectedCoins.filter(item => item.id !== coin.id)
      }else{
        this.selectedCoins.push(coin)
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.modal-title{
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.formatedText{
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  white-space: normal;
  max-width: 100%;
}
.notificationInfoModal{
  &__header-complain{
    flex-shrink: 0;
  }
  position: absolute;
  /deep/ .modal-header{
    border: none;
  }
  /deep/ .modal-footer{
    border: none;
    padding-top: 0;
  }
  &__comment-input-counter{
    position: absolute;
    bottom: 23px;
    right: 12px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.3px;
    opacity: 0.5;
    display: block;
  }
  &__header{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    &-close{
      position: absolute;
      cursor: pointer;
      user-select: none;
      right: 0;
      &:hover {
        color: $danger;
      }
    }
    &-complain{
      margin-left: 8px;
      cursor: pointer;
      user-select: none;
      &:hover {
        color: $danger;
      }
    }
    &-pin{
      display: inline-flex;
      width: fit-content;
      border-radius: 10rem;
      padding: 0.25em 0.6em;
      background: var(--primary);
      font-size: 13px;
      line-height: 1;
      margin-left: 5px;
      &.--inactive{
        background-color: var(--dark);
      }
    }
  }
  &__label{
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.3px;
    opacity: 0.5;
    display: block;
    margin-bottom: 4px;
    &._header {
      margin-bottom: 0;
    }
    @include media-breakpoint-up(md){
      font-size: 14px;
      line-height: 17px;
    }
  }
  &__header-inner-1 {
    gap: 8px 32px
  }
  &__value{
    font-weight: 500;
    font-size: 19px;
    line-height: 23px;
    display: flex;
    align-items: center;
    @include media-breakpoint-up(md){
      font-size: 21px;
      line-height: 25px;
    }
    &.--terms{
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      @include media-breakpoint-up(md){
        font-size: 15px;
        line-height: 18px;
      }
    }
  }
  &__section{
    position: relative;
    padding: 16px 0;
    border-top: 1px solid rgba(105, 117, 130, 0.51);
  }
  &__coinsShowToggler{
    user-select: none;
    width: fit-content;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    padding: 3px 8px;
    gap: 5px;
    background: var(--secondary);
    border-radius: 50px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    &-showIcon{

    }
    &-arrowIcon{
      width: 8px !important;
      height: 8px !important;
      transform: rotate(180deg);
      transition: 0.3s;
      &._active{
        transform: rotate(0deg);
      }
    }
  }
  &__coinsAmount{
    display: flex;
    align-items: center;
    gap: 8px;
  }
  &__coinsList{
    align-content: baseline;
    margin-top: 14px;
    min-height: 160px;
    max-height: 160px;
    overflow: auto;
    padding: 12px 42px 12px 12px;
    gap: 8px;
    display: flex;
    flex-wrap: wrap;
    background: var(--second-card-bg);
    &-item{
      cursor: pointer;
      padding: 5px 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--primary);
      border-radius: 13px;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      height: fit-content;
      border: 1px solid var(--primary);
      &._inactive{
        background: inherit;
        color: var(--primary);
      }
    }
  }
  &__author{
    display: flex;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    .value{
      font-weight: 600;
    }
    .label{
      opacity: .5;
      margin-right: 5px;
    }
  }
  &__person-pin{
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--secondary);
    margin-right: 8px;
    & svg{
      width: 16px !important;
      height: 17px !important;
      fill: transparent;
    }
  }
  &__action-button{
    height: 36px;
    // @include media-breakpoint-up(md){
      width: 180px;
    // }
  }
  &__controls{
    width: 100%;
    display: flex;
    align-items: center;
    // margin-top: 20px;
    justify-content: space-between;
  }
  &__token{
    height: 100%;
    display: flex;
    align-items: center;
    & svg{
      width: 30px !important;
      height: 30px !important;
    }
  }
  &__token-count{
    font-size: 22px;
    margin-right: 5px;
  }
  // &__ref-link{
  //   cursor: pointer;
  //   color: var(--primary);
  //   display: flex;
  //   align-items: center;
  // }
  // &__ref-text{
  //   text-decoration: underline;
  //   margin-right: 3px;
  // }
  &__hooks-wrapper{

  }
  &__hook-item{
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:not(:last-child){
      border-bottom: 1px solid var(--grid-border);
    }
  }
  &__hook-info{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__hook-controls{
    display: flex;
    align-items: center
  }
}
.category-title {
  background: var(--grid-border);
  width: 1.75rem;
  height: 1.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  flex-shrink: 0;
}

.category-text,
.category-title {
  font-size: 1.15em;
  margin-right: .5rem;
}

.category {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  text-align: left;
  width: 100%;
  display: flex;
  align-items: center;
  outline: none;
  white-space: nowrap;

  .cil-chevron {
    width: auto;
    height: 1.5rem;
    flex-shrink: 0;
    margin-left: .5rem;
    color: var(--dark);
    transition: all ease-in-out .2s;

    &._show {
      transform: rotate(-180deg);
    }
  }
}
.disabled{
  cursor: not-allowed;
  box-shadow: none !important;
}
.color-btn{
  user-select: none;
  background: none;
  outline: none;
  border: none;
  padding: 0;
  line-height: 0;
}
.v-popover{
  position: initial !important;
}
</style>
