import toastr from "toastr";
import validate from "@/assets/js/notifications/validateCondition";
import i18n from "@/i18n"

export default function (notification) {
  if (!notification.pairs.length) {
    toastr.warning(i18n.t('validateMessages.coinIsRequired'))
    return false
  }
  if (!notification.sendTypes || !notification.sendTypes.length) {
    toastr.warning(i18n.t('validateMessages.sendingTypeIsRequired'))
    return false
  }
  return true
}
