<template>
  <CModal
    :title="$t('lk.settings.deleteWatchlistModal.title', { name: currentNotification.name })"
    centered
    scrollable
    :show="show"
    @update:show="close"
    class="deleteGroupNotification"
    >
      <p>{{ $t('marketplace.deleteGroupNotificationText') }}</p>

      <template v-slot:footer>
        <div class="w-100">
          <CLoadingButtonCustom
            color="primary"
            class="mr-2"
            @click.native="deleteNotification"
            :loading="loading"
            :disabled="loading"
          >
            {{$t('general.yes')}}
          </CLoadingButtonCustom>
          <CButton
            color="dark"
            @click="close"
          >
            {{$t('general.no')}}
          </CButton>
        </div>
      </template>
    </CModal>
</template>

<script>
export default {
  name: "DeleteGroupNotification",
  props:{
    show: {
      type: Boolean,
      default: false
    },
    currentNotification:{
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      loading: false
    }
  },
  methods:{
    close(){
      this.$emit('close')
    },
    deleteNotification(){
      this.loading = true
      try{
        this.$emit('deleteNotification')
      }finally{
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.deleteGroupNotification{
  position: absolute;
}
</style>
