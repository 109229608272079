var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CModal",
    {
      staticClass: "hintModal",
      attrs: {
        centered: "",
        show: _vm.show,
        title: _vm.$t("marketplace.tokens"),
      },
      on: { "update:show": _vm.close },
      scopedSlots: _vm._u([
        {
          key: "footer-wrapper",
          fn: function () {
            return [_c("span")]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("div", { staticClass: "hintModal__content" }, [
        _c(
          "div",
          [
            _c("CIcon", { attrs: { name: "coin_3" } }),
            _c("span", [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("marketplace.tokenPopoverDescCreator")) +
                  " "
              ),
            ]),
          ],
          1
        ),
        _c(
          "div",
          [
            _c("CIcon", { attrs: { name: "coin_2" } }),
            _c("span", [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("marketplace.tokenPopoverDescInfluencer")) +
                  " "
              ),
            ]),
          ],
          1
        ),
        _c(
          "div",
          [
            _c("CIcon", { attrs: { name: "coin_1" } }),
            _c("span", [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("marketplace.tokenPopoverDescFollower")) +
                  " "
              ),
            ]),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "hintModal__btnBlock" },
        [
          _c(
            "CButton",
            {
              staticClass: "shadow-none hintModal__btn",
              attrs: { color: "primary" },
              on: { click: _vm.close },
            },
            [_vm._v(" OK ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }