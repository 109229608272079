<template>
  <CModal
    :title="$t('marketplace.complain')"
    centered
    scrollable
    :show="show"
    @update:show="close"
    class="complainModal"
    :closeOnBackdrop="false"
    :onKey="escClose"
    >
    <CTextarea
        :label="$t('lk.notifications.comment')"
        rows="5"
        :placeholder="$t('lk.notifications.comment')"
        class="mb-0 w-100"
        v-model="comment"
      />

      <template v-slot:footer>
        <div class="w-100">
          <CLoadingButtonCustom
            color="primary"
            class="mr-2"
            @click.native="complain"
            :loading="loading"
            :disabled="loading"
          >
            {{$t('marketplace.complain')}}
          </CLoadingButtonCustom>
          <CButton
            color="dark"
            @click="close"
          >
            {{$t('lk.videoEncrypt.UploadVideoModal.btnCancel')}}
          </CButton>
        </div>
      </template>
    </CModal>
</template>

<script>
import axios from "axios";
export default {
  name: "ComplainModal",
  props:{
    show: {
      type: Boolean,
      default: false
    },
    currentNotification:{
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      loading: false,
      comment: ''
    }
  },
  methods:{
    escClose(e,key){
      return key === 27
    },
    close(){
      this.$emit('close')
    },
    complain(){
      this.loading = true
      axios.post(`api/v1/signals/marketplace/${this.currentNotification.id}/complaint`,{
        comment: this.comment
      })
      .then(({status}) => {
        if (status < 300) {
          this.$toastr.success(this.$t('lk.notifications.addSuccess'))
        }
      })
      .finally(() => {
        this.loading = false
        this.close()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.complainModal{
  /deep/ .modal-footer{
    display: initial !important;
  }
  /deep/ .modal-header{
    border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
  }
}

</style>
