<template>
  <CCard
    :class="['notificationCardItem', {byUser: notificationData.byUser}]"
    color="base-card-bg">
    <CCardBody class="notificationCardItem__wrapper c-card-body h-100">
      <div class="notificationCardItem__content">
        <div v-if="notificationData.byUser" class="notificationCardItem__byUser-pin">
          <div class="notificationCardItem__byUser-pin_wrapper">
            <CIcon
              name="person1"
            />
          </div>
        </div>
        <div class="notificationCardItem__section --first">
          <div class="notificationCardItem__title">
            <div class="d-flex flex-column">
              <span class="notificationCardItem__label">
                {{ /* inLkList && byUser ? $t('marketplace.terms') :  */$t('marketplace.notificationName') }}
              </span>
              <div class="notificationCardItem__title-value">
                <!-- <template v-if="inLkList && byUser">
                  <p
                    v-for="(v, i) in notificationData.conditions"
                    :key="i"
                    class="m-0"
                  >
                    <span v-if="v.tf !== 'price'" class="mr-1">{{ v.tf }}</span>
                    <span class="mr-1">{{ notificationData.conditionName(v.param) }}</span>
                    <span>{{ v.value }}</span>
                  </p>
                </template> -->
                <!-- <template> -->
                  {{ notificationData.name }}
                <!-- </template> -->
              </div>
            </div>

            <exchange-logo :is-futures="notificationData.is_futures" />
          </div>
          <div class="notificationCardItem__controls --sm">
            <CButton
              v-if="notificationData.is_subscribed && !notificationData.is_subscribed_active && !notificationData.byUser && notificationData.can_subscribe && currentUser.getAccess(2)"
              color="warning"
              :loading="loading"
              :disabled="loading"
              class="shadow-none mr-2"
              @click.stop="reSubscribe"
              v-tooltip="{content: $t('marketplace.reSubscribeTooltip')}"
            >
              <CIcon name="cil-reload"/>
            </CButton>
            <CButton
              v-if="notificationData.can_play_pause && this.currentUser.getAccess(2)"
              :color="notificationData.isPaused ? 'warning' : 'primary'"
              :disabled="loading"
              class="shadow-none mr-2"
              @click.stop="togglePause"
              >
              <CIcon
                :name="notificationData.isPaused ? 'cil-media-play' : 'cil-media-pause'"
              />
            </CButton>
            <!-- v-if="inLkList || !byUser" -->
            <CLoadingButtonCustom
              class="notificationCardItem__action-button --sm px-4"
              :color="buttonOptions.color"
              @click.stop.native="buttonOptions.handler"
              :loading="loading"
              :disabled="loading"
            >
              <CIcon
                v-if="buttonOptions.hasIcon"
                name="cil-plus"/>
              {{ buttonOptions.label }}
            </CLoadingButtonCustom>
          </div>

          <div class="notificationCardItem__info-block --first">
            <div class="notificationCardItem__info-block-item">
              <span class="notificationCardItem__label">
                {{ $t('marketplace.coinsNumber') }}
              </span>
              <span class="notificationCardItem__info-block-value --coin-count">
                {{ notificationData.base_coins_count }}
                <CIcon
                  name="arrow-trends"
                />
              </span>
            </div>
            <div class="notificationCardItem__info-block-item">
              <span class="notificationCardItem__label">
                {{ /* inLkList && byUser ? $t('marketplace.repeats') : */ $t('marketplace.created') }}
              </span>
              <div class="notificationCardItem__info-block-value --terms">
                <template>
                  {{ creationDate | formatDate('DD.MM.YYYY') }}
                  <!-- <p
                    v-for="(v, i) in notificationData.conditions"
                    :key="i"
                    class="m-0"
                  >
                    <span v-if="v.tf !== 'price'" class="mr-1">{{ v.tf }}</span>
                    <span class="mr-1">{{ notificationData.conditionName(v.param) }}</span>
                    <span>{{ v.value }}</span>
                  </p> -->
                </template>
                <!-- <template v-else>
                  {{ $t('lk.notifications.repeat.every') }}
                </template> -->
              </div>
            </div>
            <div class="notificationCardItem__info-block-item">
              <span class="notificationCardItem__label">
                {{ /* inLkList && byUser ? $t('marketplace.repeats') : */ $t('marketplace.expire') }}
              </span>
              <div class="notificationCardItem__info-block-value --terms">
                <template>
                  {{ expirationDate | formatDate('DD.MM.YYYY') }}
                  <!-- <p
                    v-for="(v, i) in notificationData.conditions"
                    :key="i"
                    class="m-0"
                  >
                    <span v-if="v.tf !== 'price'" class="mr-1">{{ v.tf }}</span>
                    <span class="mr-1">{{ notificationData.conditionName(v.param) }}</span>
                    <span>{{ v.value }}</span>
                  </p> -->
                </template>
                <!-- <template v-else>
                  {{ $t('lk.notifications.repeat.every') }}
                </template> -->
              </div>
            </div>
          </div>
        </div>
        <div class="notificationCardItem__section">
          <div class="notificationCardItem__controls --md">
            <CButton
              v-if="notificationData.is_subscribed && !notificationData.is_subscribed_active && !notificationData.byUser && notificationData.can_subscribe && currentUser.getAccess(2)"
              color="warning"
              :loading="loading"
              :disabled="loading"
              class="shadow-none mr-2"
              @click.stop="reSubscribe"
              v-tooltip="{content: $t('marketplace.reSubscribeTooltip')}"
            >
              <CIcon name="cil-reload"/>
            </CButton>
            <CButton
              v-if="notificationData.can_play_pause && currentUser.getAccess(2)"
              :color="notificationData.isPaused ? 'warning' : 'primary'"
              :disabled="loading"
              class="shadow-none mr-2"
              @click.stop="togglePause"
              >
              <CIcon
                :name="notificationData.isPaused ? 'cil-media-play' : 'cil-media-pause'"
              />
            </CButton>
            <!-- v-if="inLkList || !byUser" -->
            <CLoadingButtonCustom
              :class="['notificationCardItem__action-button','--md','px-4']"
              :color="buttonOptions.color"
              @click.stop.native="buttonOptions.handler"
              :loading="loading"
              :disabled="loading"
              v-tooltip="subscribeButtonToolTip"
            >
              <CIcon
                v-if="buttonOptions.hasIcon"
                name="cil-plus"/>
              {{ buttonOptions.label }}
            </CLoadingButtonCustom>
          </div>

          <div class="notificationCardItem__info-block">
            <div class="notificationCardItem__info-block-item">
              <span class="notificationCardItem__label">
                {{ $t('marketplace.totalSigned') }}
              </span>
              <span class="notificationCardItem__info-block-value">
                {{ notificationData.subscribers_count }}
                <CIcon
                  name="person"
                />
                <span class="tv-icon-tag tv-tag"
                  :style="{
                    color : notificationData.tv_notify_color ? notificationData.tv_notify_color: '#ff0000',
                    'font-size' : notificationData.tv_notify_size ? notificationData.tv_notify_size + 'px' : '19px',
                    width: notificationData.tv_notify_size ? (notificationData.tv_notify_size + 5) + 'px' : '24px',
                    height: notificationData.tv_notify_size ? (notificationData.tv_notify_size + 5) + 'px' : '24px',
                    'line-height': notificationData.tv_notify_size ? (notificationData.tv_notify_size + 5) + 'px' : '24px'
                  }"
                  v-html="'&#' + (notificationData.tv_notify_icon ? notificationData.tv_notify_icon : 0xf0a2) + ';'"
                />
              </span>
              <div class="notificationCardItem__info-block-item --author">
                <div class="notificationCardItem__person-pin">
                  <CIcon
                    name="person1"
                  />
                </div>
                <span
                  v-tooltip="{
                  content: notificationData?.creator?.name,
                }"
                  class="label">
                  {{ $t('marketplace.author') }}:
                </span>
                <span
                  class="value">
                    {{ notificationData?.creator?.name }}
                    <span
                    v-if="isAuthorBot"
                    class="notificationCardItem__popover bg-dark"
                    @click.stop="hintClick"
                    v-c-popover="{
                      header: 'System bot',
                      content: $t('marketplace.botPopover'),
                      placement: 'left',
                    }"
                  >
                    ?
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="isAdmin"
        class="notificationCardItem__publish">
      <span
        @click.stop="togglePublish"
        class="notificationCardItem__publish_content">
        <CIcon
          v-if="isPublished"
          class="notificationCardItem__publish_showIcon"
          name="unshow"
        />
        <CIcon
          v-else
          class="notificationCardItem__publish_showIcon"
          name="show"
        />
        {{ isPublished ? $t('marketplace.removePublication') : $t('marketplace.returnPublication') }}
      </span>
      </div>
    </CCardBody>
  </CCard>
</template>

<script>
import moment from "moment"
import { mapGetters, mapState, mapActions } from "vuex"
import axios from 'axios'
import Notification from "@/assets/js/notifications/Notification.class"
import ExchangeLogo from "@/components/ExchangeLogo.vue";

export default {
  name: "NotificationCardItem",
  components: {
    ExchangeLogo
  },
  props: {
    notificationData: {
      type: Notification,
      default: () => ({})
    },
    inLkList: {
      type: Boolean,
      default: false,
    },
    tokens: {
      type: Array,
      default: () => []
    }
    // isSubscribed: {
    //   type: Boolean,
    //   default: false,
    // },
  },
  data() {
    return {
      loading: false,
      loadingPublish: false,
    }
  },
  computed: {
    isAuthorBot(){
      return this.notificationData?.creator?.id == 4908
    },
    creationDate(){
      return moment(this.notificationData.createdAt,'DD.MM.YYYY HH:mm')
    },
    expirationDate(){
      return moment(this.notificationData.end_published_date,'DD.MM.YYYY HH:mm')
    },
    subscribeButtonToolTip() {
      if (!this.$store.getters['user/getTariffPermissions']('group_notifications')) {
        return {content: `${this.$t('marketplace.actveTariffTooltip', {tariff: 'PRO+ALERTS'})}`}
      }

      return {content: false}
    },
    ...mapState({
      isSubscribed: state => state.browserNotify.isSubscribed,
    }),
    ...mapGetters({
      telegramVerify: 'user/telegramVerify',
    }),
    isPublished() {
      return !!this.notificationData.is_published
    },
    isAdmin() {
      return this.$store.getters['user/getPermissions']('control-education')
    },
    currentUser() {
      return this.$store.state.user.userData
    },
    // byUser() {
    //   return this.currentUser.id === this.notificationData.creator?.id
    // },
    buttonOptions() {
      if (this.notificationData.byUser) {
          return {
            label: this.$t('general.delete'),
            color: 'danger',
            handler: this.delete
          }
        }
     /*  if (this.inLkList) {
        if (this.byUser) {
          return {
            label: this.$t('general.delete'),
            color: 'danger',
            handler: this.delete
          }
        } else {
          return {
            label: this.$t('marketplace.unsubscribe'),
            color: 'secondary',
            handler: this.unsubscribe
          }
        }
      } else  */{
        if (this.notificationData.is_subscribed) {
          return {
            label: this.$t('marketplace.unsubscribe'),
            color: 'secondary',
            handler: this.unsubscribe
          }
        } else {
          return {
            label: this.$t('marketplace.subscribe'),
            color: 'primary',
            hasIcon: true,
            /* tooltip: this.subscribeButtonToolTip, */
            handler: this.subscribe
          }
        }
      }
    },
  },
  methods: {
    hintClick(){

    },
    neededToken(tokenId = 1){
      return this.tokens.find(item => item?.token_id === tokenId)
    },
    togglePause(){
      this.loading = true
      axios.post('api/v1/signals/marketplace/enable-signals', {
        enable: this.notificationData.isPaused,
        marketplace_signal_id: this.notificationData.id
      })
        .then(({status, data}) => {
          if (status < 300) {
            this.notificationData.isPaused = !this.notificationData.isPaused
          }
        })
        .finally(() => {
          // if(this.inLkList){
          //   this.$emit('updateList')
          // }
          this.$emit('updatePaused')
          this.loading = false
        })
    },
    ...mapActions('promo', ['toggleShowPromoModal']),
    togglePublish() {
      if (this.loadingPublish) return
      this.loadingPublish = true
      const url = `api/v1/signals/marketplace/${this.notificationData.id}/${this.isPublished ? 'unpublish' : 'publish'}`
      axios.post(url)
        .then(({status}) => {
          if (status < 300) {
            this.$toastr.success(this.$t(this.isPublished ? 'lk.notifications.unpublished' : 'lk.notifications.published'))
            this.$emit('updateList')
          }
        })
        .finally(() => {
          this.loadingPublish = false
        })
    },
    subscribe() {
      if (!this.currentUser.getAccess(2)) {
        this.toggleShowPromoModal({show: true, content: { id: 'pro_alerts', sliderData: [{src:'promo1'}, {src:'promo2'}, {src:'promo3'}]}})
        return
      }
      this.loading = true
      const send_types = []
      if (this.isSubscribed) send_types.push('browser_push')
      if (this.telegramVerify) send_types.push('telegram')
      if (send_types.length) {
        if (!this.neededToken(1).active_token_count && !this.neededToken(2).active_token_count && !this.neededToken(3).active_token_count){
          this.$toastr.warning(this.$t('marketplace.tokenLimitWarning', {type: 'FOLLOWER/CREATOR/INFLUENCER'}))
          this.loading = false
          return
        }
        axios.post(`api/v1/signals/marketplace/${this.notificationData.id}/subscribe`, {
          subscribe_duration: 30,
          send_types,
          pairs: this.notificationData.coins_pairs,
          comment: this.notificationData.comment
        })
          .then(({status, data}) => {
            if (status < 300) {
              if (data.error) {
                this.$toastr.warning(data.error)
              } else {
                this.$toastr.success(this.$t('lk.notifications.subscribeSuccess'))
                this.$emit('updateList')
              }
            }
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        this.$emit('showInfoModal')
        this.loading = false
      }
    },
    unsubscribe() {
      if (!this.currentUser.getAccess(2)) {
        this.toggleShowPromoModal({show: true, content: { id: 'pro_alerts', sliderData: [{src:'promo1'}, {src:'promo2'}, {src:'promo3'}]}})
        return
      }
      this.loading = true
      axios.post(`api/v1/signals/marketplace/${this.notificationData.id}/unsubscribe`)
        .then(({status, data}) => {
          if (status < 300) {
            if (data.error) {
              this.$toastr.warning(data.error)
            } else {
              this.$toastr.success(this.$t('lk.notifications.unsubscribeSuccess'))
              this.$emit('updateList')
            }
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    async reSubscribe(){
      this.loading = true
      try{
        const res = await axios.post(`api/v1/signals/marketplace/${this.notificationData.id}/resubscribe`)
        if(res.status < 300){
          if(res.data.error){
            this.$toastr.warning(res.data.error)
          }else{
            this.$toastr.success(this.$t('lk.notifications.subscribeSuccess'))
            this.$emit('updateList')
          }
        }
      } catch(e){
        console.error(e)
      }
      finally {
        this.loading = false
      }
    },
    delete() {
      if (!this.currentUser.getAccess(2)) {
        this.toggleShowPromoModal({show: true, content: { id: 'pro_alerts', sliderData: [{src:'promo1'}, {src:'promo2'}, {src:'promo3'}]}})
        return
      }
      this.$emit('delete', this.notificationData)
    }
  }
}
</script>

<style lang="scss" scoped>
.notificationCardItem {
  margin-bottom: 0;
  border-radius: 10px;
  cursor: pointer;
  /deep/ .arrow{
    display: none;
  }
  /deep/ .c-loading-button-spinner{
    flex-shrink: 0;
  }
  &__popover{
    user-select: none;
    display: inline-flex;
    justify-content: center;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    cursor: pointer;
    font-size: 0.75rem;
    margin-left: 4px;
  }
  &__byUser-pin {
    position: absolute;
    background: #72808F;
    width: 53px;
    height: 53px;
    bottom: -27px;
    right: -27px;
    transform: rotate(45deg);

    &_wrapper {
      position: relative;
      width: 100%;
      height: 100%;

      & svg {
        position: absolute;
        fill: transparent;
        transform: rotate(-45deg);
        bottom: 20px;
        left: 2px;
      }
    }
  }

  &.byUser {
    .notificationCardItem__wrapper {
      border: 2px solid #72808F;
    }

    .notificationCardItem__person-pin {
      background: var(--primary);
    }

    .--author {
      .value {
        color: var(--primary);
      }
    }
  }

  &__wrapper {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    border: 2px solid transparent;
    transition: .3s all;

    &:hover {
      border-color: var(--primary) !important;
      opacity: .7;
    }

    // display: flex;
    // flex-direction: column;
    // @include media-breakpoint-up(md){
    //   display: flex;
    //   flex-direction: row;
    //   justify-content: space-between;
    // }
  }

  &__content {
    // flex-grow: 1;
    @include media-breakpoint-up(md) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__section {
    display: flex;
    flex-direction: column;

    &.--first {
      @include media-breakpoint-up(md) {
        margin-right: 30px;
        max-width: 50%;
        width: 100%;
      }
    }
  }

  &__title {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 12px;
    margin-bottom: 12px;

    @include media-breakpoint-up(md) {
      margin-bottom: 20px;
      flex-direction: row;
      justify-content: space-between;
      gap: 20px;
    }

    @include media-breakpoint-up(xl) {
      flex-direction: column;
    }

    @include media-breakpoint-up(xxl) {
      flex-direction: row;
    }
  }

  &__label {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.3px;
    opacity: 0.5;
    margin-bottom: 4px;
    display: inline-block;
    @include media-breakpoint-up(md) {
      line-height: 16px;
      font-size: 13px;
    }
  }

  &__title-value {
    font-weight: 500;
    font-size: 19px;
    line-height: 23px;
    display: -webkit-box;
    display: -moz-box;
    // display: flex;
    max-height: 45px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -moz-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    box-orient: vertical;
    width: 100%;
    @include media-breakpoint-up(md) {
      font-size: 20px;
      line-height: 24px;
    }
  }

  &__info-block {
    &.--first{
      display: flex;
      justify-content: space-between;

      @include media-breakpoint-up(md) {
        justify-content: flex-start;
      }
    }
    @include media-breakpoint-up(md) {
      display: flex;
      column-gap: 30px;
    }
  }

  &__controls{
    margin-bottom: 16px;
    &.--sm {
      display: flex;
      @include media-breakpoint-up(md) {
        display: none;
      }
    }
    &.--md {
      display: none;
      @include media-breakpoint-up(md) {
        display: flex;
        margin-bottom: 21px;
      }
    }
  }
  &__action-button {
    // margin-bottom: 16px;
    justify-content: center;

    &.--sm {
      display: flex;
      flex-grow: 1;
      // @include media-breakpoint-up(md) {
      //   display: none;
      // }
    }

    &.--md {
      // display: none;
      // width: 180px;
      flex-grow: 1;
      @include media-breakpoint-up(md) {
        // display: flex;
        // margin-bottom: 21px;

      }
    }

    svg {
      margin-right: 5px;
      flex-shrink: 0;
    }
  }

  &__info-block-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    &.--sm {
      @include media-breakpoint-up(md) {
        display: none;
      }
    }

    &.--author {
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      flex-direction: row;
      align-items: center;
      margin-top: 10px;

      .value {
        font-weight: 600;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @include media-breakpoint-up(md) {
          width: 135px;
        }
        @include media-breakpoint-up(lg) {
          width: 145px;
        }
        @media (min-width:1199px) and (max-width:1300px) {
          width: 110px;
        }
        @include media-breakpoint-up(xxl) {
          width: 155px;
        }
      }

      .label {
        opacity: .5;
        margin-right: 5px;
      }
    }
  }

  &__info-block-value {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    @include media-breakpoint-up(md) {
      // max-width: 164px;
      font-size: 21px;
      line-height: 25px;
    }

    svg {
      margin-left: 9px;
    }

    &.--coin-count {
      color: var(--primary);

      svg {
        margin-left: 8px;
        transform: rotate(90deg);
        width: 10px !important;
        height: 10px !important;
      }
    }

    &.--terms {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      @include media-breakpoint-up(md) {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        display: -webkit-box;
        display: -moz-box;
        // display: flex;
        max-height: 45px;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -moz-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        -moz-box-orient: vertical;
        box-orient: vertical;
      }
    }
  }

  &__person-pin {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--secondary);
    margin-right: 8px;

    & svg {
      width: 14px !important;
      height: 15px !important;
      fill: transparent;
    }
  }

  &__publish {
    display: flex;
    justify-content: center;
    border-top: 1px solid var(--grid-border);
    padding-top: 16px;

    &_content {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      transition: .3s;

      &:hover {
        color: var(--primary);

        & svg {
          fill: var(--primary);
        }
      }

      @include media-breakpoint-up(lg) {
        font-size: 16px;
        line-height: 19px;
      }
    }

    &_showIcon {
      fill: var(--secondary);
      margin-right: 6px;
      transition: .3s;
      @include media-breakpoint-up(md) {
        margin-right: 8px;
      }
    }
  }
}
.tv-tag{
  margin-left: auto;
}
</style>
