var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: ["tokens-block", { lk: _vm.inLkList }] },
    [
      _c(
        "div",
        { staticClass: "tokens-block__list" },
        _vm._l(_vm.formatedList, function (item, index) {
          return _c(
            "div",
            { staticClass: "tokens-block__tokens-item", on: { key: index } },
            [
              _c("CIcon", {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: { content: item.token.name },
                    expression: "{content: item.token.name}",
                  },
                ],
                attrs: { name: `coin_${item.token_id}` },
              }),
              _vm._v(" " + _vm._s(item.active_token_count) + " "),
            ],
            1
          )
        }),
        0
      ),
      _c(
        "span",
        {
          staticClass:
            "rounded-circle tokens-block__popover-wrap d-inline-flex justify-content-center ml-2",
          on: {
            click: function ($event) {
              _vm.showHintModal = true
            },
          },
        },
        [_vm._v(" ? ")]
      ),
      false
        ? _c(
            "div",
            { staticClass: "tokens-block__tokens-buy" },
            [_c("CIcon", { attrs: { name: "cil-plus" } })],
            1
          )
        : _vm._e(),
      _vm.showHintModal
        ? _c("HintModal", {
            attrs: { show: _vm.showHintModal },
            on: {
              close: function ($event) {
                _vm.showHintModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }