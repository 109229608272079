var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "exchange-logo d-flex flex-column align-items-start" },
    [
      _c("img", {
        class: "mb-1 exchange-logo__img",
        attrs: {
          src: _vm.exchangeAndType.logo,
          alt: _vm.exchangeAndType.name,
          loading: "lazy",
        },
      }),
      _c("span", { staticClass: "exchange-logo__text" }, [
        _vm._v(" " + _vm._s(_vm.exchangeAndType.type) + " "),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }