<template>
  <div class="exchange-logo d-flex flex-column align-items-start">
    <img
      :class="'mb-1 exchange-logo__img'"
      :src="exchangeAndType.logo"
      :alt="exchangeAndType.name"
      :loading="'lazy'"
    />
    <span class="exchange-logo__text">
      {{ exchangeAndType.type}}
    </span>
  </div>
</template>

<script>
import binanceLogoDark from '@/assets/img/binance-logo-dark.svg';
import binanceLogoLight from '@/assets/img/binance-logo-light.svg';

export default {
  name: "ExchangeLogo",
  props: {
    isFutures: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    darkMode() {
      return this.$store.getters.darkMode;
    },
    exchangeAndType() {
      return {
        logo: this.darkMode ? binanceLogoDark : binanceLogoLight,
        name: 'BINANCE',
        exchange: 'binance',
        type: this.isFutures ? 'FUTURES' : 'SPOT',
      }
    },
  },
}
</script>

<style lang="scss">
.exchange-logo {
  font-weight: 600;
  font-size: 14px;
  margin-top: 4px;

  &__img {
    width: 80px;
    height: auto;
  }

  &__text {
    opacity: .3;
  }
}
</style>
