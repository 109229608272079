var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c(
        "CModal",
        {
          staticClass: "notificationInfoModal",
          attrs: {
            show: _vm.show,
            centered: "",
            scrollable: "",
            onKey: _vm.escClose,
            closeOnBackdrop: false,
          },
          on: { "update:show": _vm.close },
          scopedSlots: _vm._u(
            [
              {
                key: "header",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "notificationInfoModal__header" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "notificationInfoModal__header-inner-1 d-flex flex-wrap align-items-end gap-3",
                          },
                          [
                            _c("div", [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "notificationInfoModal__label _header",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("marketplace.notificationName")
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "notificationInfoModal__value" },
                                [
                                  _c("div", { staticClass: "modal-title" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.currentNotification.name) +
                                        " "
                                    ),
                                  ]),
                                  !_vm.isGuest
                                    ? _c("CIcon", {
                                        staticClass:
                                          "notificationInfoModal__header-complain",
                                        attrs: { name: "complain" },
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.complain.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.inLkList &&
                                  _vm.currentNotification.is_public
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "notificationInfoModal__header-pin",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("general.public")) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.inLkList &&
                                  !_vm.currentNotification.can_subscribe
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "notificationInfoModal__header-pin --inactive",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "lk.notifications.isInActive"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]),
                            _c("ExchangeLogo", {
                              attrs: { "is-futures": _vm.isFutures },
                            }),
                          ],
                          1
                        ),
                        _c("CIcon", {
                          staticClass: "notificationInfoModal__header-close",
                          attrs: { name: "cil-x" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.close.apply(null, arguments)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "footer",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "notificationInfoModal__controls" },
                      [
                        [
                          !_vm.isSubscribed && !_vm.telegramVerify
                            ? _c(
                                "CButton",
                                {
                                  staticClass: "cbtn flex-grow-1",
                                  attrs: {
                                    disabled: _vm.loading,
                                    color: "dark",
                                  },
                                  on: { click: _vm.close },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("general.close")) + " "
                                  ),
                                ]
                              )
                            : [
                                !_vm.isLog
                                  ? _c(
                                      "CLoadingButtonCustom",
                                      {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip",
                                            value: _vm.subscribeButtonToolTip,
                                            expression:
                                              "subscribeButtonToolTip",
                                          },
                                        ],
                                        staticClass:
                                          "notificationInfoModal__action-button",
                                        attrs: {
                                          loading: _vm.loading,
                                          disabled: _vm.loading,
                                          color: _vm.buttonOptions.color,
                                        },
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.buttonOptions.handler.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm.buttonOptions.hasIcon
                                          ? _c("CIcon", {
                                              attrs: { name: "cil-plus" },
                                            })
                                          : _vm._e(),
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.buttonOptions.label) +
                                            " "
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.currentNotification.is_subscribed &&
                                !_vm.currentNotification.is_subscribed_active &&
                                !_vm.currentNotification.byUser &&
                                _vm.currentNotification.can_subscribe &&
                                _vm.currentUser.getAccess(2)
                                  ? _c(
                                      "CButton",
                                      {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip",
                                            value: {
                                              content: _vm.$t(
                                                "marketplace.reSubscribeTooltip"
                                              ),
                                            },
                                            expression:
                                              "{content: $t('marketplace.reSubscribeTooltip')}",
                                          },
                                        ],
                                        attrs: {
                                          loading: _vm.loading,
                                          disabled: _vm.loading,
                                          color: "warning",
                                        },
                                        on: { click: _vm.reSubscribe },
                                      },
                                      [
                                        _c("CIcon", {
                                          attrs: { name: "cil-reload" },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.inLkList &&
                                !_vm.currentNotification.can_subscribe
                                  ? _c(
                                      "CButton",
                                      {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip",
                                            value: {
                                              content: _vm.$t(
                                                "marketplace.reCreateTooltip"
                                              ),
                                            },
                                            expression:
                                              "{content: $t('marketplace.reCreateTooltip')}",
                                          },
                                        ],
                                        attrs: {
                                          loading: _vm.loading,
                                          disabled: _vm.loading,
                                          color: "warning",
                                        },
                                        on: { click: _vm.recreateNotification },
                                      },
                                      [
                                        _c("CIcon", {
                                          attrs: { name: "cil-reload" },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                !(
                                  _vm.currentNotification.is_subscribed ||
                                  _vm.currentNotification.byUser
                                ) && !_vm.isLog
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "notificationInfoModal__token",
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "notificationInfoModal__token-count",
                                          },
                                          [_vm._v(" 1 ")]
                                        ),
                                        _c("CIcon", {
                                          directives: [
                                            {
                                              name: "tooltip",
                                              rawName: "v-tooltip",
                                              value: {
                                                content: _vm.$t(
                                                  "marketplace.assignTokenTooltip"
                                                ),
                                              },
                                              expression:
                                                "{content: $t('marketplace.assignTokenTooltip')}",
                                            },
                                          ],
                                          attrs: { name: "coin_1" },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.currentNotification.can_subscribe &&
                                _vm.currentNotification.is_public
                                  ? _c(
                                      "CButton",
                                      {
                                        staticClass:
                                          "notificationInfoModal__action-button",
                                        attrs: { color: "secondary" },
                                        on: { click: _vm.copyLink },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("general.share")) +
                                            " "
                                        ),
                                        _c("CIcon", {
                                          attrs: { name: "copy" },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                !_vm.currentNotification.is_public &&
                                !_vm.currentNotification.is_single
                                  ? _c(
                                      "CButton",
                                      {
                                        attrs: { color: "success" },
                                        on: { click: _vm.editSignal },
                                      },
                                      [
                                        _c("CIcon", {
                                          attrs: { name: "cil-pencil" },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                        ],
                      ],
                      2
                    ),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            false,
            3963190816
          ),
        },
        [
          _c("ComplainModal", {
            attrs: {
              show: _vm.showComplainModal,
              currentNotification: _vm.currentNotification,
            },
            on: {
              close: function ($event) {
                _vm.showComplainModal = false
              },
            },
          }),
          _c("ConfirmModal", {
            attrs: { show: _vm.showConfirmModal, info: _vm.confirmModalInfo },
            on: { close: _vm.closeConfirmModal, confirm: _vm.confirmHandler },
          }),
          _c("hook-modal", {
            attrs: {
              currentHook: _vm.currentHook,
              show: _vm.showCreateHookModal,
              "is-futures": _vm.isFutures,
            },
            on: { saveHook: _vm.saveHook, close: _vm.closeHookModal },
          }),
          !_vm.telegramVerify || !_vm.isSubscribed
            ? [
                _c(
                  "button",
                  {
                    staticClass: "mb-3 category",
                    on: {
                      click: function ($event) {
                        _vm.collapseNotifySettings = !_vm.collapseNotifySettings
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "category-title" }, [
                      _vm._v(" 1 "),
                    ]),
                    _c("span", { staticClass: "category-text" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("lk.notifications.notificationSettings")
                          ) +
                          " "
                      ),
                    ]),
                    _c("span", { staticClass: "dropdown-divider w-100" }),
                    _c("CIcon", {
                      staticClass: "cil-chevron",
                      class: { _show: !_vm.collapseNotifySettings },
                      attrs: { name: "cil-chevron-circle-down-alt" },
                    }),
                  ],
                  1
                ),
                _c(
                  "c-collapse",
                  {
                    attrs: { show: !_vm.collapseNotifySettings, duration: 200 },
                  },
                  [
                    _vm.$store.getters["user/getPermissions"](
                      "view_notifications"
                    ) && !_vm.isSubscribed
                      ? _c("web-push-toggle")
                      : _vm._e(),
                    !_vm.telegramVerify
                      ? _c("confirm-tg", {
                          staticClass: "px-2 mb-2 mt-3",
                          attrs: {
                            color: "primary",
                            text: _vm.$t("lk.account.connectTelegram"),
                            isRequestPassed: _vm.telegramVerify,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm.telegramVerify || _vm.isSubscribed
            ? [
                _c(
                  "div",
                  { staticClass: "notificationInfoModal__section" },
                  [
                    _c(
                      "span",
                      { staticClass: "notificationInfoModal__label" },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("marketplace.coinsNumber")) + " "
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "notificationInfoModal__coinsAmount" },
                      [
                        _c(
                          "span",
                          { staticClass: "notificationInfoModal__value" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.currentNotification.base_coins_count
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _vm.canUserSubscribe
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "notificationInfoModal__coinsShowToggler",
                                on: {
                                  click: function ($event) {
                                    _vm.showCoinList = !_vm.showCoinList
                                  },
                                },
                              },
                              [
                                _vm.showCoinList
                                  ? _c("CIcon", {
                                      staticClass:
                                        "notificationInfoModal__coinsShowToggler-showIcon",
                                      attrs: { name: "unshow" },
                                    })
                                  : _c("CIcon", {
                                      staticClass:
                                        "notificationInfoModal__coinsShowToggler-showIcon",
                                      attrs: { name: "show" },
                                    }),
                                _vm._v(
                                  " " + _vm._s(_vm.$t("marketplace.show")) + " "
                                ),
                                _c("CIcon", {
                                  class: [
                                    "notificationInfoModal__coinsShowToggler-arrowIcon",
                                    { _active: _vm.showCoinList },
                                  ],
                                  attrs: { name: "arrow-trends" },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    ),
                    _c("transition", { attrs: { name: "fade", appear: "" } }, [
                      _vm.showCoinList
                        ? _c(
                            "div",
                            { staticClass: "notificationInfoModal__coinsList" },
                            _vm._l(
                              _vm.currentNotification.coins_pairs,
                              function (item) {
                                return _c(
                                  "span",
                                  {
                                    key: item.id,
                                    class: {
                                      "notificationInfoModal__coinsList-item": true,
                                      _inactive: !_vm.isCoinPinActive(item),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.coinPinClick(item)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          `${item.exchange}:${item.symbol}`
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          )
                        : _vm._e(),
                    ]),
                  ],
                  1
                ),
                !_vm.isFutures
                  ? _c(
                      "div",
                      { staticClass: "notificationInfoModal__section" },
                      [
                        _vm.currentNotification.sync_watchlist
                          ? _c("CInputCheckbox", {
                              attrs: {
                                disabled: "",
                                checked: _vm.currentNotification.sync_watchlist,
                                custom: "",
                                label: _vm.$t("lk.notifications.syncedFutures"),
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("div", { staticClass: "notificationInfoModal__section" }, [
                  _c("div", { staticClass: "d-flex" }, [
                    _c("div", [
                      _c(
                        "span",
                        { staticClass: "notificationInfoModal__label" },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("marketplace.createdAt")) + " "
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "notificationInfoModal__coinsAmount" },
                        [_vm._v(" " + _vm._s(_vm.publishedDate) + " ")]
                      ),
                    ]),
                    _vm.currentNotification.subscribe_finished_at
                      ? _c("div", { staticClass: "ml-3" }, [
                          _c(
                            "span",
                            { staticClass: "notificationInfoModal__label" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("marketplace.subscribeEndDate")
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "notificationInfoModal__coinsAmount",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.currentNotification
                                      .subscribe_finished_at
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]),
                _vm.canUserSubscribe
                  ? _c(
                      "div",
                      { staticClass: "notificationInfoModal__section" },
                      [
                        _c(
                          "span",
                          { staticClass: "notificationInfoModal__label" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("marketplace.activity")) +
                                ": "
                            ),
                          ]
                        ),
                        _c(
                          "CButtonGroup",
                          { staticClass: "d-flex" },
                          _vm._l(_vm.subscribeDurationList, function (item, i) {
                            return _c(
                              "CButton",
                              {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: _vm.getSubscribeDurationTooltip(
                                      item.value
                                    ),
                                    expression:
                                      "getSubscribeDurationTooltip(item.value)",
                                  },
                                ],
                                key: i,
                                staticClass: "cbutton",
                                class: {
                                  disabled: _vm.getDurationTabDisabled(
                                    item.value
                                  ),
                                },
                                attrs: {
                                  color: "dark",
                                  variant:
                                    _vm.currentNotification.subscribe_duration
                                      .id === item.id
                                      ? ""
                                      : "outline",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.setSubscribeDuration(item)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      `${item.value} ${_vm.$tc(
                                        item?.labelI18n,
                                        item.value
                                      )}`
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.canUserSubscribe
                  ? _c(
                      "div",
                      { staticClass: "mb-4" },
                      [
                        _c(
                          "span",
                          { staticClass: "notificationInfoModal__label" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("lk.notifications.target.title")
                                ) +
                                ": "
                            ),
                          ]
                        ),
                        _c(
                          "CButtonGroup",
                          { staticClass: "d-flex" },
                          _vm._l(
                            _vm.NotificationClass.sendTypes,
                            function (type, i) {
                              return _c(
                                "CButton",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: {
                                        content: _vm.getTooltipContent(type),
                                      },
                                      expression:
                                        "{\n            content: getTooltipContent(type),\n          }",
                                    },
                                  ],
                                  key: i,
                                  staticClass: "cbutton",
                                  class: {
                                    disabled: _vm.getSendTypeDisabled(type),
                                  },
                                  attrs: {
                                    disabled: _vm.loading,
                                    color: "dark",
                                    variant: _vm.getSendType(type)
                                      ? ""
                                      : "outline",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setSendType(type)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.getSendTypeLabel(type)) +
                                      " "
                                  ),
                                ]
                              )
                            }
                          ),
                          1
                        ),
                        _c(
                          "transition",
                          { attrs: { name: "fade", appear: "" } },
                          [
                            _vm.isSendTypesChanged &&
                            _vm.currentNotification.is_public &&
                            _vm.inLkList
                              ? _c(
                                  "CButton",
                                  {
                                    staticClass: "cbtn mt-2",
                                    attrs: {
                                      disabled: _vm.loading,
                                      color: "success",
                                    },
                                    on: { click: _vm.saveSignal },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("general.apply")) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("transition", { attrs: { name: "fade", appear: "" } }, [
                  !(
                    !_vm.currentNotification.is_single &&
                    !_vm.currentNotification.is_public &&
                    !_vm.currentNotification.hooks.length
                  ) && _vm.sendTypes.includes("hook")
                    ? _c(
                        "div",
                        { staticClass: "notificationInfoModal__section" },
                        [
                          _c(
                            "span",
                            { staticClass: "notificationInfoModal__label" },
                            [_vm._v(" Webhook: ")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "notificationInfoModal__hooks-wrapper",
                            },
                            _vm._l(
                              _vm.currentNotification.hooks,
                              function (item, i) {
                                return _c(
                                  "div",
                                  {
                                    key: i,
                                    staticClass:
                                      "notificationInfoModal__hook-item",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "notificationInfoModal__hook-info mr-3",
                                      },
                                      [_vm._v(" " + _vm._s(item.name) + " ")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "notificationInfoModal__hook-controls",
                                      },
                                      [
                                        !_vm.currentNotification.is_public
                                          ? _c(
                                              "CButton",
                                              {
                                                attrs: { color: "primary" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.showHook(item, i)
                                                  },
                                                },
                                              },
                                              [
                                                _c("CIcon", {
                                                  attrs: { name: "cil-info" },
                                                }),
                                              ],
                                              1
                                            )
                                          : [
                                              _c("CSwitch", {
                                                staticClass:
                                                  "switch-custom mr-3",
                                                attrs: {
                                                  color: "primary",
                                                  shape: "pill",
                                                  checked: item.enable,
                                                  disabled:
                                                    _vm.currentNotification
                                                      .byUser ||
                                                    _vm.currentNotification
                                                      .is_subscribed,
                                                  id: "switch",
                                                },
                                                on: {
                                                  "update:checked": function (
                                                    $event
                                                  ) {
                                                    return _vm.$set(
                                                      item,
                                                      "enable",
                                                      $event
                                                    )
                                                  },
                                                },
                                              }),
                                              _c(
                                                "CButton",
                                                {
                                                  staticClass: "mr-2",
                                                  attrs: { color: "success" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.editHook(
                                                        item,
                                                        i
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("CIcon", {
                                                    attrs: {
                                                      name: "cil-pencil",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "CButton",
                                                {
                                                  attrs: { color: "danger" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.deleteHook(
                                                        item,
                                                        i
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("CIcon", {
                                                    attrs: {
                                                      name: "cil-trash",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                      ],
                                      2
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                          !_vm.currentNotification.hooks.length
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "CButton",
                                    {
                                      staticClass:
                                        "shadow-none text-white px-0",
                                      attrs: {
                                        disabled:
                                          _vm.loading ||
                                          (this.currentNotification
                                            .is_subscribed &&
                                            this.currentNotification.is_public),
                                      },
                                      on: { click: _vm.addHook },
                                    },
                                    [
                                      _c("CIcon", {
                                        staticClass:
                                          "cc-icon bg-dark align-bottom rounded-circle my-0 mr-1 p-1",
                                        attrs: { name: "cil-plus" },
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("general.add")) +
                                          " "
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "notificationInfoModal__section" }, [
                  _c("span", { staticClass: "notificationInfoModal__label" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("marketplace.totalSigned")) + " "
                    ),
                  ]),
                  _c("div", [
                    _c(
                      "span",
                      { staticClass: "notificationInfoModal__value" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.currentNotification.subscribers_count) +
                            " "
                        ),
                        _c("CIcon", { attrs: { name: "person" } }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "notificationInfoModal__author" },
                      [
                        _c(
                          "div",
                          { staticClass: "notificationInfoModal__person-pin" },
                          [_c("CIcon", { attrs: { name: "person1" } })],
                          1
                        ),
                        _c("span", { staticClass: "label" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("marketplace.author")) + ": "
                          ),
                        ]),
                        _c("span", { staticClass: "value" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.currentNotification?.creator?.name) +
                              " "
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ]),
                _vm.canUserSubscribe
                  ? _c(
                      "div",
                      { staticClass: "notificationInfoModal__section" },
                      [
                        _c(
                          "span",
                          { staticClass: "notificationInfoModal__label" },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("marketplace.terms")) + " "
                            ),
                          ]
                        ),
                        _vm.initLoading
                          ? _c(
                              "div",
                              [
                                _c("CSpinner", {
                                  class: "mt-1",
                                  attrs: { size: "sm", color: "primary" },
                                }),
                              ],
                              1
                            )
                          : _c(
                              "div",
                              {
                                staticClass:
                                  "notificationInfoModal__value d-flex flex-column align-items-start --terms",
                              },
                              _vm._l(
                                _vm.currentNotification.conditions,
                                function (v, i) {
                                  return _c(
                                    "p",
                                    { key: i, staticClass: "m-0" },
                                    [
                                      v.tf !== "price"
                                        ? _c("span", { staticClass: "mr-1" }, [
                                            _vm._v(_vm._s(v.tf)),
                                          ])
                                        : _vm._e(),
                                      _c("span", { staticClass: "mr-1" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.currentNotification.conditionName(
                                              v.param
                                            )
                                          )
                                        ),
                                      ]),
                                      v.hasValue
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                v.isNegative ? v.sign : ""
                                              ) +
                                                _vm._s(
                                                  _vm.getValue(v.param, v.value)
                                                )
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                      ]
                    )
                  : _vm._e(),
                _vm.canUserSubscribe && _vm.currentNotification.coins_count > 1
                  ? _c(
                      "div",
                      { staticClass: "notificationInfoModal__section" },
                      [
                        _c(
                          "div",
                          { staticClass: "d-flex mb-2 align-items-center" },
                          [
                            _c("CInputCheckbox", {
                              staticClass: "mr-3",
                              attrs: {
                                checked:
                                  _vm.currentNotification.tv_notify_enable,
                                custom: "",
                                label: _vm.$t(
                                  "lk.notifications.plotOnTheChart"
                                ),
                                disabled: _vm.currentNotification.is_subscribed,
                              },
                              on: {
                                "update:checked": function ($event) {
                                  return _vm.$set(
                                    _vm.currentNotification,
                                    "tv_notify_enable",
                                    $event
                                  )
                                },
                              },
                            }),
                            _c(
                              "transition",
                              { attrs: { name: "fade", appear: "" } },
                              [
                                _vm.currentNotification.tv_notify_enable
                                  ? _c(
                                      "tv-icons-picker",
                                      {
                                        attrs: {
                                          open: _vm.openTvIconPicker,
                                          color:
                                            _vm.currentNotification
                                              .tv_notify_color,
                                          icon: _vm.currentNotification
                                            .tv_notify_icon,
                                          size: _vm.currentNotification
                                            .tv_notify_size,
                                        },
                                        on: {
                                          "update:open": function ($event) {
                                            _vm.openTvIconPicker = $event
                                          },
                                          "update:color": function ($event) {
                                            return _vm.$set(
                                              _vm.currentNotification,
                                              "tv_notify_color",
                                              $event
                                            )
                                          },
                                          "update:icon": function ($event) {
                                            return _vm.$set(
                                              _vm.currentNotification,
                                              "tv_notify_icon",
                                              $event
                                            )
                                          },
                                          "update:size": function ($event) {
                                            return _vm.$set(
                                              _vm.currentNotification,
                                              "tv_notify_size",
                                              $event
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            directives: [
                                              {
                                                name: "tooltip",
                                                rawName: "v-tooltip",
                                                value: {
                                                  content: _vm
                                                    .currentNotification
                                                    .is_subscribed
                                                    ? null
                                                    : _vm.$t(
                                                        "lk.tg_notifications.edit"
                                                      ),
                                                },
                                                expression:
                                                  "{\n                content: currentNotification.is_subscribed ? null : $t('lk.tg_notifications.edit'),\n              }",
                                              },
                                            ],
                                            staticClass: "color-btn",
                                            on: {
                                              click:
                                                _vm.tvIconPickerClickHandler,
                                            },
                                          },
                                          [
                                            _c("span", {
                                              staticClass: "tv-icon-tag",
                                              style: {
                                                color: _vm.currentNotification
                                                  .tv_notify_color
                                                  ? _vm.currentNotification
                                                      .tv_notify_color
                                                  : "#ff0000",
                                                "font-size": _vm
                                                  .currentNotification
                                                  .tv_notify_size
                                                  ? _vm.currentNotification
                                                      .tv_notify_size + "px"
                                                  : "19px",
                                                width: _vm.currentNotification
                                                  .tv_notify_size
                                                  ? _vm.currentNotification
                                                      .tv_notify_size +
                                                    5 +
                                                    "px"
                                                  : "24px",
                                                height: _vm.currentNotification
                                                  .tv_notify_size
                                                  ? _vm.currentNotification
                                                      .tv_notify_size +
                                                    5 +
                                                    "px"
                                                  : "24px",
                                                "line-height": _vm
                                                  .currentNotification
                                                  .tv_notify_size
                                                  ? _vm.currentNotification
                                                      .tv_notify_size +
                                                    5 +
                                                    "px"
                                                  : "24px",
                                              },
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  "&#" +
                                                    (_vm.currentNotification
                                                      .tv_notify_icon
                                                      ? _vm.currentNotification
                                                          .tv_notify_icon
                                                      : 0xf0a2) +
                                                    ";"
                                                ),
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "transition",
                          { attrs: { name: "fade", appear: "" } },
                          [
                            _vm.currentNotification.tv_notify_enable
                              ? _c(
                                  "div",
                                  { staticClass: "mb-4" },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "notificationInfoModal__label",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "lk.notifications.plotOnChartDuration"
                                              )
                                            ) +
                                            ": "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "CButtonGroup",
                                      { staticClass: "d-flex" },
                                      _vm._l(
                                        _vm.NotificationClass
                                          .plotOnChartDurationList,
                                        function (item, i) {
                                          return _c(
                                            "CButton",
                                            {
                                              key: i,
                                              staticClass: "cbutton",
                                              attrs: {
                                                color: "dark",
                                                disabled:
                                                  _vm.currentNotification
                                                    .is_subscribed,
                                                variant:
                                                  _vm.currentNotification
                                                    .tv_notify_days.id ===
                                                  item?.id
                                                    ? ""
                                                    : "outline",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.$set(
                                                    _vm.currentNotification,
                                                    "tv_notify_days",
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    item?.labelI18n
                                                      ? `${
                                                          item.value
                                                        } ${_vm.$tc(
                                                          item?.labelI18n,
                                                          item.value
                                                        )}`
                                                      : _vm.$t(item.label)
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.currentNotification.description
                  ? _c(
                      "div",
                      { staticClass: "notificationInfoModal__section" },
                      [
                        _c(
                          "span",
                          { staticClass: "notificationInfoModal__label" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "lk.videoEncrypt.EditModal.description"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "span",
                          {
                            staticClass:
                              "notificationInfoModal__value --terms formatedText",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.currentNotification.description) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.currentNotification.comment &&
                _vm.canUserSubscribe &&
                (_vm.currentNotification.is_subscribed ||
                  _vm.currentNotification.byUser)
                  ? _c(
                      "div",
                      { staticClass: "notificationInfoModal__section" },
                      [
                        _c(
                          "span",
                          { staticClass: "notificationInfoModal__label" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("lk.notifications.comment")) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "notificationInfoModal__value --terms",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.currentNotification.comment) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                !_vm.currentNotification.is_subscribed &&
                _vm.canUserSubscribe &&
                !_vm.currentNotification.byUser
                  ? _c(
                      "div",
                      { staticClass: "notificationInfoModal__section" },
                      [
                        _c(
                          "span",
                          { staticClass: "notificationInfoModal__label" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("lk.notifications.comment")) +
                                " "
                            ),
                          ]
                        ),
                        _c("CTextarea", {
                          ref: "commentInput",
                          staticClass: "mb-0 w-100",
                          attrs: {
                            rows: _vm.commentLengthLimit === 50 ? 4 : 5,
                            value: _vm.comment,
                          },
                          on: { input: _vm.commentInputHandler },
                        }),
                        _c(
                          "span",
                          {
                            staticClass:
                              "notificationInfoModal__comment-input-counter",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.commentLengthLimit) +
                                "/" +
                                _vm._s(_vm.comment.length) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }