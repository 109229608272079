var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CModal",
    {
      staticClass: "complainModal",
      attrs: {
        title: _vm.$t("marketplace.complain"),
        centered: "",
        scrollable: "",
        show: _vm.show,
        closeOnBackdrop: false,
        onKey: _vm.escClose,
      },
      on: { "update:show": _vm.close },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "w-100" },
                [
                  _c(
                    "CLoadingButtonCustom",
                    {
                      staticClass: "mr-2",
                      attrs: {
                        color: "primary",
                        loading: _vm.loading,
                        disabled: _vm.loading,
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.complain.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("marketplace.complain")) + " ")]
                  ),
                  _c(
                    "CButton",
                    { attrs: { color: "dark" }, on: { click: _vm.close } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("lk.videoEncrypt.UploadVideoModal.btnCancel")
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("CTextarea", {
        staticClass: "mb-0 w-100",
        attrs: {
          label: _vm.$t("lk.notifications.comment"),
          rows: "5",
          placeholder: _vm.$t("lk.notifications.comment"),
        },
        model: {
          value: _vm.comment,
          callback: function ($$v) {
            _vm.comment = $$v
          },
          expression: "comment",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }