<template>
  <div
    :class="['tokens-block',{lk: inLkList} ]">
    <div class="tokens-block__list">
      <div
      v-for="(item,index) in formatedList"
      class="tokens-block__tokens-item"
      @key="index">
        <CIcon
        v-tooltip="{content: item.token.name}"
        :name="`coin_${item.token_id}`"/>
        {{ item.active_token_count }}
      </div>
    </div>
    <span
    @click="showHintModal = true"
    class="rounded-circle tokens-block__popover-wrap d-inline-flex justify-content-center ml-2">
      ?
    </span>
    <div
      v-if="false"
      class="tokens-block__tokens-buy">
      <CIcon name="cil-plus"/>
    </div>
    <HintModal
      v-if="showHintModal"
      :show="showHintModal"
      @close="showHintModal=false"
      />
  </div>
</template>

<script>
import HintModal from '@/components/marketplace/HintModal'
export default {
 name: "TokensBlock",
 components: {
    HintModal
  },
 props: {
   tokens: {
     type: Array,
     default: ()=>[]
   },
   inLkList: {
      type: Boolean,
      default: false,
    },
 },
 data() {
    return {
      showHintModal: false,
    }
  },
  computed:{
    formatedList(){
      return this.tokens.sort((a,b) => b.token_id - a.token_id)
    }
  }
}
</script>

<style lang="scss" scoped>
.tokens-block{
  &.lk{
    & .tokens-block__list,
    & .tokens-block__popover-wrap{
      background-color: var(--second-card-bg);
    }

  }
    display: flex;
    align-items: center;
    // margin-top: 12px;
    // @include media-breakpoint-up(md) {
    //   margin-top: 16px;
    // }
    // @include media-breakpoint-up(xxl) {
    //   margin-top: 18px;
    // }
  &__list{
    padding: 7px 0;
    border-radius: 6px;
    background-color: var(--base-card-bg);
    display: flex;
    width: 160px;
    @include media-breakpoint-up(lg) {
      width: 170px;
    }
    @media (min-width:1199px) and (max-width:1300px) {
      width: 134.28px;
    }
    @include media-breakpoint-up(xxl) {
      width: 180px;
    }
  }
  &__tokens-item{
    // padding: 0 10px;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    width: 33%;
    justify-content: center;
    @include media-breakpoint-up(xxl) {
      font-size: 15px;
      line-height: 18px;
    }
    & svg{
      margin-right: 6px;
      width: 20px !important;
      height: 20px !important;
      @media (min-width:1199px) and (max-width:1300px) {
        margin-right: 3px;
      }
      @include media-breakpoint-up(xxl) {
        margin-right: 8px;
        width: 22px !important;
        height: 22px !important;
      }
    }
    &:not(:last-child){
      border-right: 1px solid var(--secondary);
    }
  }
  &__tokens-buy{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    height: 100%;
    width: 34px;
  }
  &__popover-wrap{
    cursor: pointer;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: 1rem;
    flex-shrink: 0;
    height: 1rem;
    font-size: 0.75rem;
    background-color: var(--base-card-bg);
  }
}
</style>
