var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CCard",
    {
      class: ["notificationCardItem", { byUser: _vm.notificationData.byUser }],
      attrs: { color: "base-card-bg" },
    },
    [
      _c(
        "CCardBody",
        { staticClass: "notificationCardItem__wrapper c-card-body h-100" },
        [
          _c("div", { staticClass: "notificationCardItem__content" }, [
            _vm.notificationData.byUser
              ? _c("div", { staticClass: "notificationCardItem__byUser-pin" }, [
                  _c(
                    "div",
                    { staticClass: "notificationCardItem__byUser-pin_wrapper" },
                    [_c("CIcon", { attrs: { name: "person1" } })],
                    1
                  ),
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "notificationCardItem__section --first" },
              [
                _c(
                  "div",
                  { staticClass: "notificationCardItem__title" },
                  [
                    _c("div", { staticClass: "d-flex flex-column" }, [
                      _c(
                        "span",
                        { staticClass: "notificationCardItem__label" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                /* inLkList && byUser ? $t('marketplace.terms') : */ _vm.$t(
                                  "marketplace.notificationName"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "notificationCardItem__title-value" },
                        [_vm._v(" " + _vm._s(_vm.notificationData.name) + " ")]
                      ),
                    ]),
                    _c("exchange-logo", {
                      attrs: { "is-futures": _vm.notificationData.is_futures },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "notificationCardItem__controls --sm" },
                  [
                    _vm.notificationData.is_subscribed &&
                    !_vm.notificationData.is_subscribed_active &&
                    !_vm.notificationData.byUser &&
                    _vm.notificationData.can_subscribe &&
                    _vm.currentUser.getAccess(2)
                      ? _c(
                          "CButton",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: {
                                  content: _vm.$t(
                                    "marketplace.reSubscribeTooltip"
                                  ),
                                },
                                expression:
                                  "{content: $t('marketplace.reSubscribeTooltip')}",
                              },
                            ],
                            staticClass: "shadow-none mr-2",
                            attrs: {
                              color: "warning",
                              loading: _vm.loading,
                              disabled: _vm.loading,
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.reSubscribe.apply(null, arguments)
                              },
                            },
                          },
                          [_c("CIcon", { attrs: { name: "cil-reload" } })],
                          1
                        )
                      : _vm._e(),
                    _vm.notificationData.can_play_pause &&
                    this.currentUser.getAccess(2)
                      ? _c(
                          "CButton",
                          {
                            staticClass: "shadow-none mr-2",
                            attrs: {
                              color: _vm.notificationData.isPaused
                                ? "warning"
                                : "primary",
                              disabled: _vm.loading,
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.togglePause.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _c("CIcon", {
                              attrs: {
                                name: _vm.notificationData.isPaused
                                  ? "cil-media-play"
                                  : "cil-media-pause",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "CLoadingButtonCustom",
                      {
                        staticClass:
                          "notificationCardItem__action-button --sm px-4",
                        attrs: {
                          color: _vm.buttonOptions.color,
                          loading: _vm.loading,
                          disabled: _vm.loading,
                        },
                        nativeOn: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.buttonOptions.handler.apply(
                              null,
                              arguments
                            )
                          },
                        },
                      },
                      [
                        _vm.buttonOptions.hasIcon
                          ? _c("CIcon", { attrs: { name: "cil-plus" } })
                          : _vm._e(),
                        _vm._v(" " + _vm._s(_vm.buttonOptions.label) + " "),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "notificationCardItem__info-block --first" },
                  [
                    _c(
                      "div",
                      { staticClass: "notificationCardItem__info-block-item" },
                      [
                        _c(
                          "span",
                          { staticClass: "notificationCardItem__label" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("marketplace.coinsNumber")) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "span",
                          {
                            staticClass:
                              "notificationCardItem__info-block-value --coin-count",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.notificationData.base_coins_count) +
                                " "
                            ),
                            _c("CIcon", { attrs: { name: "arrow-trends" } }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "notificationCardItem__info-block-item" },
                      [
                        _c(
                          "span",
                          { staticClass: "notificationCardItem__label" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  /* inLkList && byUser ? $t('marketplace.repeats') : */ _vm.$t(
                                    "marketplace.created"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "notificationCardItem__info-block-value --terms",
                          },
                          [
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("formatDate")(
                                      _vm.creationDate,
                                      "DD.MM.YYYY"
                                    )
                                  ) +
                                  " "
                              ),
                            ],
                          ],
                          2
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "notificationCardItem__info-block-item" },
                      [
                        _c(
                          "span",
                          { staticClass: "notificationCardItem__label" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  /* inLkList && byUser ? $t('marketplace.repeats') : */ _vm.$t(
                                    "marketplace.expire"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "notificationCardItem__info-block-value --terms",
                          },
                          [
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("formatDate")(
                                      _vm.expirationDate,
                                      "DD.MM.YYYY"
                                    )
                                  ) +
                                  " "
                              ),
                            ],
                          ],
                          2
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _c("div", { staticClass: "notificationCardItem__section" }, [
              _c(
                "div",
                { staticClass: "notificationCardItem__controls --md" },
                [
                  _vm.notificationData.is_subscribed &&
                  !_vm.notificationData.is_subscribed_active &&
                  !_vm.notificationData.byUser &&
                  _vm.notificationData.can_subscribe &&
                  _vm.currentUser.getAccess(2)
                    ? _c(
                        "CButton",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: {
                                content: _vm.$t(
                                  "marketplace.reSubscribeTooltip"
                                ),
                              },
                              expression:
                                "{content: $t('marketplace.reSubscribeTooltip')}",
                            },
                          ],
                          staticClass: "shadow-none mr-2",
                          attrs: {
                            color: "warning",
                            loading: _vm.loading,
                            disabled: _vm.loading,
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.reSubscribe.apply(null, arguments)
                            },
                          },
                        },
                        [_c("CIcon", { attrs: { name: "cil-reload" } })],
                        1
                      )
                    : _vm._e(),
                  _vm.notificationData.can_play_pause &&
                  _vm.currentUser.getAccess(2)
                    ? _c(
                        "CButton",
                        {
                          staticClass: "shadow-none mr-2",
                          attrs: {
                            color: _vm.notificationData.isPaused
                              ? "warning"
                              : "primary",
                            disabled: _vm.loading,
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.togglePause.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c("CIcon", {
                            attrs: {
                              name: _vm.notificationData.isPaused
                                ? "cil-media-play"
                                : "cil-media-pause",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "CLoadingButtonCustom",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.subscribeButtonToolTip,
                          expression: "subscribeButtonToolTip",
                        },
                      ],
                      class: [
                        "notificationCardItem__action-button",
                        "--md",
                        "px-4",
                      ],
                      attrs: {
                        color: _vm.buttonOptions.color,
                        loading: _vm.loading,
                        disabled: _vm.loading,
                      },
                      nativeOn: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.buttonOptions.handler.apply(
                            null,
                            arguments
                          )
                        },
                      },
                    },
                    [
                      _vm.buttonOptions.hasIcon
                        ? _c("CIcon", { attrs: { name: "cil-plus" } })
                        : _vm._e(),
                      _vm._v(" " + _vm._s(_vm.buttonOptions.label) + " "),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "notificationCardItem__info-block" }, [
                _c(
                  "div",
                  { staticClass: "notificationCardItem__info-block-item" },
                  [
                    _c("span", { staticClass: "notificationCardItem__label" }, [
                      _vm._v(
                        " " + _vm._s(_vm.$t("marketplace.totalSigned")) + " "
                      ),
                    ]),
                    _c(
                      "span",
                      { staticClass: "notificationCardItem__info-block-value" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.notificationData.subscribers_count) +
                            " "
                        ),
                        _c("CIcon", { attrs: { name: "person" } }),
                        _c("span", {
                          staticClass: "tv-icon-tag tv-tag",
                          style: {
                            color: _vm.notificationData.tv_notify_color
                              ? _vm.notificationData.tv_notify_color
                              : "#ff0000",
                            "font-size": _vm.notificationData.tv_notify_size
                              ? _vm.notificationData.tv_notify_size + "px"
                              : "19px",
                            width: _vm.notificationData.tv_notify_size
                              ? _vm.notificationData.tv_notify_size + 5 + "px"
                              : "24px",
                            height: _vm.notificationData.tv_notify_size
                              ? _vm.notificationData.tv_notify_size + 5 + "px"
                              : "24px",
                            "line-height": _vm.notificationData.tv_notify_size
                              ? _vm.notificationData.tv_notify_size + 5 + "px"
                              : "24px",
                          },
                          domProps: {
                            innerHTML: _vm._s(
                              "&#" +
                                (_vm.notificationData.tv_notify_icon
                                  ? _vm.notificationData.tv_notify_icon
                                  : 0xf0a2) +
                                ";"
                            ),
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "notificationCardItem__info-block-item --author",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "notificationCardItem__person-pin" },
                          [_c("CIcon", { attrs: { name: "person1" } })],
                          1
                        ),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: {
                                  content: _vm.notificationData?.creator?.name,
                                },
                                expression:
                                  "{\n                content: notificationData?.creator?.name,\n              }",
                              },
                            ],
                            staticClass: "label",
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("marketplace.author")) + ": "
                            ),
                          ]
                        ),
                        _c("span", { staticClass: "value" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.notificationData?.creator?.name) +
                              " "
                          ),
                          _vm.isAuthorBot
                            ? _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "c-popover",
                                      rawName: "v-c-popover",
                                      value: {
                                        header: "System bot",
                                        content: _vm.$t(
                                          "marketplace.botPopover"
                                        ),
                                        placement: "left",
                                      },
                                      expression:
                                        "{\n                    header: 'System bot',\n                    content: $t('marketplace.botPopover'),\n                    placement: 'left',\n                  }",
                                    },
                                  ],
                                  staticClass:
                                    "notificationCardItem__popover bg-dark",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.hintClick.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" ? ")]
                              )
                            : _vm._e(),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
          _vm.isAdmin
            ? _c("div", { staticClass: "notificationCardItem__publish" }, [
                _c(
                  "span",
                  {
                    staticClass: "notificationCardItem__publish_content",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.togglePublish.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _vm.isPublished
                      ? _c("CIcon", {
                          staticClass: "notificationCardItem__publish_showIcon",
                          attrs: { name: "unshow" },
                        })
                      : _c("CIcon", {
                          staticClass: "notificationCardItem__publish_showIcon",
                          attrs: { name: "show" },
                        }),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.isPublished
                            ? _vm.$t("marketplace.removePublication")
                            : _vm.$t("marketplace.returnPublication")
                        ) +
                        " "
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }